.root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 100%;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 571px;
  margin: 53px auto;
  padding: 60px 100px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 8px 52px #f3f3f3;

  &-back-options {
    display: flex;
    align-items: center;
    margin-bottom: 32px;

    &__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 52px;
      height: 52px;
      background-color: var(--light-grey);
      border: none;
      border-radius: 16px;
      transform: rotate(180deg);
      cursor: pointer;

      svg {
        width: 15px;
        height: 12px;

        path {
          transition: 0.2s;
          fill: var(--secondary-black);
        }
      }

      &:hover {
        svg path {
          fill: var(--black);
        }
      }

      @media (max-width: 640px) {
        width: unset;
        height: unset;
        background-color: unset;
        border-radius: unset;

        svg {
          width: 12.5px;
          height: 10px;
        }
      }
    }

    &__back-text {
      margin-left: 16px;
      color: var(--dark-grey);

      @media (max-width: 640px) {
        margin-left: 11px;
      }
    }

    @media (max-width: 640px) {
      position: absolute;
      top: 88px;
    }
  }

  @media (max-width: 640px) {
    position: inherit;
    margin: auto;
    padding: 0 24px;
    border-radius: unset;
    box-shadow: none;
  }
}

.footer {
  justify-content: center;
  margin-top: auto;
  padding-bottom: 48px;

  @media (max-width: 620px) {
    flex-direction: column;
    justify-content: center;
    padding: 12px 24px 24px 24px;
  }

  &__link {
    color: var(--dark-grey);
    font-weight: 500;
    line-height: 20px;
  }
}
