$block: '.email-verification';

#{$block} {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__header {
    margin-top: 16px;
    margin-bottom: 40px;
    word-break: break-word;

    @media (max-width: 640px) {
      margin-bottom: 45px;
    }
  }

  &__subtitle {
    color: var(--dark-grey);

    @media (max-width: 640px) {
      display: inline-block;
      max-width: 400px;
    }
  }

  &__link {
    margin-left: 6px;
    text-decoration: none;
    outline: none;
    transition: color 0.2s;

    &:hover {
      color: var(--dark-grey);
    }
  }

  &__field {
    &:not(:last-of-type) {
      margin-bottom: 24px;
    }

    &:last-of-type {
      margin-bottom: 36px;
    }
  }

  &__repeat-code {
    color: var(--dark-grey);
  }

  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
