.card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: var(--white);
  font-weight: bold;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.01em;
  background: var(--background-primary);
  border-radius: 16px;

  &_small-height {
    min-height: 206px;

    @media (max-width: 600px) {
      min-height: 200px;
    }
  }

  &_big-height {
    min-height: 212px;

    @media (max-width: 600px) {
      min-height: 169px;
    }
  }

  &_blue {
    color: var(--white);
    background: var(--background-primary);
    border-radius: 16px;
  }

  &_light {
    color: var(--secondary-black);
    background: var(--white);
    border-left: 4px solid var(--red);
    border-radius: 2px 16px 16px 2px;
    box-shadow: 0 8px 52px #f3f3f3;
  }

  &_big {
    padding: 36px;

    @media (max-width: 600px) {
      padding: 16px;
    }
  }

  &_small {
    padding: 16px 20px 20px;
  }

  .title {
    overflow-wrap: break-word;

    &_big {
      font-size: 28px;
      line-height: 36px;
    }

    &_small {
      font-size: 20px;
      line-height: 24px;
    }

    @media (max-width: 600px) {
      &_big {
        font-size: 18px;
        line-height: 22px;
      }

      &_small {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .content {
    display: -webkit-box;
    margin-top: 12px;
    font-weight: normal;

    &_big {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    &_small {
      font-size: 14px;
      line-height: 18px;
    }

    @media (max-width: 600px) {
      &_big {
        font-size: 14px;
        line-height: 18px;
        // color: var(--dark-grey);
      }

      &_small {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .link-more {
    display: flex;
    margin-top: auto;

    &__text {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: flex-start;
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-decoration: none;

      &_blue {
        color: var(--white);

        svg {
          path {
            fill: var(--white);
          }
        }
      }

      &_light {
        color: var(--secondary-black);

        svg {
          path {
            fill: var(--secondary-black);
          }
        }
      }

      svg {
        width: 15px;
        height: 12px;
        margin-left: 12px;
      }
    }
  }
}

.date_container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.date {
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;

  @media (max-width: 600px) {
    font-size: 12px;
  }
}
