.radio {
  display: flex;
  gap: 20px;
  align-items: center;

  input[type="radio"] {
    display: grid;
    width: 1.5em;
    height: 1.5em;
    margin: 0;
    color: #646efa;
    font: inherit;
    background-color: #fff;
    border: 0.15em solid #646efa;
    border-radius: 50%;
    appearance: none;
    place-content: center;

    &::before {
      width: 0.95em;
      height: 0.95em;
      border-radius: 50%;
      box-shadow: inset 1em 1em #646efa;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
      content: "";
    }

    &:checked::before {
      transform: scale(1);
    }
  }
}
