.history {
  height: 100%;

  &_loading {
    opacity: 0.6;
  }

  @media (max-width: 840px) {
    &__button {
      width: 202px;
    }
  }

  @media (max-width: 480px) {
    &__button {
      width: 161px !important;
    }
  }
}

.container {
  height: 100%;
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 800px) {
    margin-bottom: 20px;
    box-shadow: none;
  }
}

.item {
  width: 47%;
  height: 100%;

  &:nth-child(odd) {
    padding-top: 32px;
    padding-left: 46px;
  }

  &:nth-child(even) {
    padding-top: 32px;
    padding-right: 46px;
  }

  &:last-child {
    padding-bottom: 60px;
  }

  @media (max-width: 940px) {
    &:nth-child(odd) {
      padding-top: 32px;
      padding-left: 40px;
    }

    &:nth-child(even) {
      padding-top: 32px;
      padding-right: 40px;
    }

    &:last-child {
      padding-bottom: 40px;
    }
  }

  @media (max-width: 800px) {
    width: 100%;

    &:nth-child(odd) {
      padding-top: 16px;
      padding-left: unset;
    }

    &:nth-child(even) {
      padding-top: 16px;
      padding-right: unset;
    }

    &:last-child {
      padding-bottom: unset;
    }

    &:first-child {
      padding-top: unset;
    }
  }
}

.list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.waiting {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  padding: 60px 80px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 840px) {
    padding: unset;
    box-shadow: unset;
  }
}

.tabs__container {
  display: flex;
  padding: 48px 46px 8px 46px;

  @media (max-width: 768px) {
    padding: 0 0 22px 0;
  }
}

.container_banner {
  width: 100%;
  margin-bottom: 20px;

  @media (min-width: 768px) {
    p {
      max-width: 600px;
    }
  }
}
