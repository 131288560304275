.biomarkers-item {
  margin-bottom: 32px;

  @media (max-width: 767px) {
    flex-wrap: nowrap !important;
    margin-bottom: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: 767px) {
    margin-bottom: 12px;
  }

  &__title {
    width: 100%;
    max-width: 307px;
    padding: 16px;
    background: var(--light-grey);
    border-radius: 16px;

    &-text {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @media (max-width: 576px) {
        display: -webkit-box;
        white-space: unset;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }

  &__delete-btn {
    width: 20px;
    height: 20px;
    margin: auto 0;
    padding: 0;
    background: none;
    border: none;
    cursor: pointer;
    appearance: none;
  }

  &__mobile-delete-btn {
    margin-bottom: 40px;

    @media (min-width: 767px) {
      display: none;
    }
  }

  &__norm {
    @media (max-width: 767px) {
      display: none;
    }
  }

  &__mobile-bottom-wrapper {
    margin-right: -15px;
    margin-left: -15px;
  }

  &__mobile-norm {
    margin-bottom: 10px;
    padding-top: 4px;
    color: var(--dark-grey);
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;

    @media (min-width: 767px) {
      display: none;
    }
  }

  &__unit {
    @media (max-width: 576px) {
      padding-right: 0;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.biomarkers__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  color: var(--secondary-black);
  text-align: center;
}
