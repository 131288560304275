.dropdown {
  position: relative;
  width: fit-content;

  &_max {
    width: 100%;
  }

  &__title {
    margin-bottom: 12px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 212px;
    padding: 14px 16px;
    background-color: var(--light-grey);
    border: 1px solid var(--light-grey);
    border-radius: 16px;
    outline: none;
    transition: all 0.2s;

    @media (max-width: 640px) {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &_max {
      max-width: 100%;
    }

    &_big {
      @media (max-width: 640px) {
        padding-top: 12px;
        padding-bottom: 12px;
      }
    }

    &:focus,
    &:focus-within {
      border: 1px solid var(--grey);
    }

    @media (max-width: 560px) {
      border-radius: 14px;
    }
  }

  &__input {
    width: 100%;
    margin-right: 16px;
    color: var(--secondary-black);
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
    background-color: var(--light-grey);
    border: none;
    outline: none;
    cursor: default;

    &::placeholder {
      color: var(--dark-grey);
    }

    &_error {
      color: var(--red);
      border: 1px solid var(--red);
    }

    &_warning {
      border: 1px solid var(--yellow);
    }

    @media (max-width: 640px) {
      margin-right: unset;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__icon {
    display: flex;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: transform 0.2s;

    &_show {
      transform: rotate(180deg);
    }

    svg {
      path {
        transition: 0.2s;
        fill: var(--dark-grey);
      }
    }

    &:hover {
      svg path {
        fill: var(--secondary-black);
      }
    }
  }

  &__body {
    position: absolute;
    right: 0;
    z-index: 2;
    margin-top: 4px;
    border-radius: 16px;

    &_left {
      right: none;
      left: 0;
    }

    @media (max-width: 385px) {
      min-width: 320px;
    }
  }

  &__item {
    padding: 16px 20px;
    outline: none;
    cursor: pointer;
    transition: 0.2s;

    &:focus {
      background-color: var(--grey);
    }

    &:hover {
      background-color: var(--grey);
    }
  }

  &:focus {
    outline: none;
  }
}
