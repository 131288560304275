@import 'src/assets/scss/common/typografics';

.empty-state {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 175px 64px;
  border-radius: 12px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 720px) {
    padding: 80px 64px;
    box-shadow: unset;
  }

  &__wraper {
    padding: 0 60px 60px 60px;

    @media (max-width: 768px) {
      padding-right: 24px;
      padding-left: 24px;
    }

    @media (max-width: 500px) {
      padding: 0;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    gap: 32px;
    justify-content: space-between;
    margin-top: 32px;
    padding-right: 60px;

    @media (max-width: 970px) {
      padding-right: 24px;
    }

    @media (max-width: 860px) {
      flex-direction: column;
      font-size: 16px;
    }

    @media (max-width: 400px) {
      margin-left: 24px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    margin-bottom: 40px;
  }

  &__title {
    margin-bottom: 24px;
    text-align: center;

    h6 {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__subtitle {
    margin-bottom: 40px;
    text-align: center;
  }

  &__subtitle_grey {
    color: var(--dark-grey);
  }

  &__text {
    max-width: 340px;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}
