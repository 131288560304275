.root {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 418px;
  padding: 48px;
  background: var(--white);
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 720px) {
    padding: 24px;
  }
}

.features {
  margin-bottom: 32px;
  list-style: disc;
  list-style-position: inside;
}

.feature {
  margin-bottom: 16px;
  color: var(--dark-grey);
  font-weight: normal;
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  letter-spacing: -0.01em;

  &:last-child {
    margin-bottom: 0;
  }
}

.actions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > button {
    height: 56px;

    &:not(:last-of-type) {
      margin-bottom: 20px;
    }
  }
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;

  @media (max-width: 760px) {
    flex-direction: column;
    row-gap: 12px;
  }
}

.header_container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.title {
  color: var(--black);
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.subtitle {
  color: var(--blue);
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
}

.price_inner {
  display: flex;
  align-items: end;
  column-gap: 4px;
}

.price_container {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.price {
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.priceLabel {
  color: var(--dark-grey);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: right;

  @media (max-width: 760px) {
    text-align: left;
  }
}

.active_subscriptions {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.active_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
  column-gap: 8px;
}

.active_heading {
  color: var(--background-secondary);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}

.active_subheading {
  display: flex;
  color: var(--dark-grey);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}
