.autoimmune {
  margin-bottom: 40px;

  @media (max-width: 968px) {
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 12px;

    &_edit {
      margin-bottom: 12px;
      color: var(--black);
      font-weight: bold;
      font-size: 18px;
      font-style: normal;
      line-height: 22px;
      letter-spacing: -0.01em;
    }
  }

  &__text {
    //max-width: 695px;
    white-space: break-spaces;
  }
}
