.link-back {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: 12.5px;
    height: 10px;
    margin-right: 11px;
    transform: rotate(180deg);

    path {
      transition: all 0.2s;
      fill: var(--dark-grey);
    }
  }

  p {
    color: var(--dark-grey);
    transition: all 0.2s;
  }

  &:hover {
    p {
      color: var(--text-primary);
    }

    svg path {
      fill: var(--text-primary);
    }
  }
}
