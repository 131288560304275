$block: '.sign-up';

#{$block} {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__form {
    margin-top: 40px;
  }

  &__footer {
    margin: 32px auto 0 auto;
  }

  &__subtitle {
    margin-top: 24px;
    color: var(--dark-grey);
    line-height: 24px;
  }

  &__link {
    margin-left: 6px;
    text-decoration: none;
    outline: none;
    transition: color 0.2s;

    &:hover {
      color: var(--dark-grey);
    }
  }

  &__field {
    &:not(:last-of-type) {
      margin-bottom: 24px;

      @media (max-width: 640px) {
        margin-bottom: 28px;
      }
    }

    &:last-of-type {
      margin-bottom: 36px;
    }
  }

  &__terms {
    display: flex;
    align-items: flex-start;

    &-text {
      padding-left: 14px;

      &--error {
        color: var(--red);
      }
    }

    &-link {
      color: var(--background-primary);
    }
  }
}
