$block: '.forgot-password-changed';

#{$block} {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__header {
    margin-bottom: 85px;
  }

  &__icon {
    margin-bottom: 105px;
  }

  @media (max-width: 640px) {
    &__header {
      margin-bottom: 65px;
      text-align: center;

      span {
        display: inline-block;
        width: 100%;
      }
    }

    &__icon {
      width: 100px;
      height: 100px;
      margin-bottom: 70px;
    }
  }
}
