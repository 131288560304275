.container {
  display: flex;
  flex-direction: column;
  margin: 40px 18vw;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 210px;
  height: 210px;
  padding: 24px;
  background-color: white;
  border: none;
  border-radius: 24px;
  box-shadow: 0 8px 52px #f3f3f3;
  cursor: pointer;
  transition: background-color 0.2s ease;

  @media (max-width: 576px) {
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 68px;
    margin-bottom: 16px;
    padding: 14px 16px;
  }

  &__title {
    @media (max-width: 576px) {
      font-weight: bold;
      font-size: 16px;
      font-style: normal;
      line-height: 20px;
    }
  }

  &__text_grey {
    color: var(--dark-grey);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  &:hover {
    background-color: var(--lighter-grey);
    transition: background-color 0.2s ease;
  }

  &:not(:first-child) {
    margin-left: 32px;

    @media (max-width: 576px) {
      margin-left: 0;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: var(--light-grey);
    border-radius: 16px;

    @media (max-width: 576px) {
      width: 40px;
      height: 40px;
      margin-right: 12px;
      border-radius: 12px;
    }

    svg g {
      fill: var(--background-primary);
    }

    svg path {
      fill: var(--background-primary);
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    :first-child {
      margin-bottom: 8px;
    }
  }
}

.back-button {
  display: flex;
  align-items: center;
  margin-bottom: 92px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    background-color: var(--light-grey);
    border-radius: 16px;
    transform: rotate(180deg);
    cursor: pointer;

    svg path {
      transition: 0.2s;
      fill: var(--secondary-black);
    }

    &:hover {
      svg path {
        fill: var(--black);
      }
    }
  }
}

.title {
  @media (max-width: 576px) {
    font-size: 24px;
    line-height: 32px;
  }
}

.subtitle {
  margin-top: 10px;
  margin-bottom: 60px;
  color: #b4b4b4;

  @media (max-width: 576px) {
    margin-bottom: 32px;
  }
}

.cards-container {
  display: flex;

  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.force-wrap {
  width: 50%;
  text-align: start;

  @media (max-width: 768px) {
    width: 100%;
  }
}

.banner {
  max-width: 691px;
}
