.body {
  color: var(--black);
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.01em;

  &_regular {
    font-weight: normal;
  }

  &_medium {
    font-weight: 500;
  }

  &_bold {
    font-weight: bold;
  }

  @media (max-width: 640px) {
    font-size: 14px;
    line-height: 18px;
  }
}

.subhead {
  color: var(--black);
  font-size: 14px;
  font-style: normal;
  line-height: 18px;
  letter-spacing: -0.01em;

  &_regular {
    font-weight: normal;
  }

  &_medium {
    font-weight: 500;
  }

  &_bold {
    font-weight: bold;
  }

  @media (max-width: 760px) {
    font-size: 12px;
    line-height: 16px;
  }
}

.footnote {
  color: var(--black);
  font-size: 12px;
  font-style: normal;
  line-height: 16px;
  letter-spacing: -0.01em;

  &_medium {
    font-weight: 500;
  }

  &_regular {
    font-weight: normal;
  }
}

.headline {
  color: var(--black);
  font-weight: 600;
  font-size: 18px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: -0.01em;

  @media (max-width: 760px) {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
}

h1 {
  color: var(--black);
  font-weight: bold;
  font-size: 42px;
  font-style: normal;
  line-height: 42px;
  letter-spacing: -0.01em;
}

h2 {
  color: var(--black);
  font-weight: 800;
  font-size: 32px;
  font-style: normal;
  line-height: 42px;
  letter-spacing: -0.01em;

  @media (max-width: 640px) {
    font-size: 28px;
    line-height: 36px;
  }
}

h3 {
  color: var(--black);
  font-weight: 800;
  font-size: 28px;
  font-style: normal;
  line-height: 36px;
  letter-spacing: -0.01em;
}

h4 {
  color: var(--black);
  font-weight: bold;
  font-size: 24px;
  font-style: normal;
  line-height: 32px;
  letter-spacing: -0.01em;
}

h5 {
  color: var(--black);
  font-weight: bold;
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.01em;
}

h6 {
  color: var(--black);
  font-weight: bold;
  font-size: 18px;
  font-style: normal;
  line-height: 22px;
  letter-spacing: -0.01em;
}

.dark-grey {
  color: var(--dark-grey);
}
