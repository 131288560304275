.cell {
  padding-top: 24px;
  padding-bottom: 24px;
  border-bottom: 1px solid #e8e8e8;

  &__container {
    padding-left: 60px;

    @media (max-width: 1100px) {
      width: 1100px;
    }
  }

  &__dot {
    min-width: 10px;
    min-height: 10px;
    margin-right: 10px;
    background: #35b185;
    border-radius: 50%;
  }

  &__dot_grey {
    min-width: 10px;
    min-height: 10px;
    margin-right: 10px;
    background: var(--light-grey);
    border-radius: 50%;
  }
}

.header {
  display: flex;
  align-items: center;

  &__button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__name {
    display: flex;
    align-items: center;
    width: calc(100% / 7.7 * 1.7);
  }

  &__birthday {
    display: flex;
    align-items: center;
    justify-content: start;
    width: calc(100% / 7.7 * 1.3);
  }

  &__phone {
    display: flex;
    align-items: center;
    width: calc(100% / 7.7 * 1.5);
  }

  &__mail {
    display: flex;
    align-items: center;
    width: calc(100% / 7.7 * 1.8);
    padding-right: 15px;
  }

  &__date {
    display: flex;
    align-items: center;
    width: calc(100% / 7.7 * 1.22);
  }

  &__container {
    display: flex;
    margin-top: 32px;
    padding-top: 23px;
    padding-bottom: 23px;
    padding-left: 60px;
    background: #f9f9f9;

    @media (max-width: 1100px) {
      width: 1100px;
    }
  }

  &__text_grey {
    margin-right: 15px;
    color: var(--dark-grey);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    overflow-wrap: anywhere;

    &:hover {
      color: var(--text-primary);
      transition: 0.7s;
    }
  }

  &__text {
    margin-right: 15px;
    color: var(--black);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    overflow-wrap: anywhere;

    &:hover {
      color: var(--dark-grey);
    }
  }
}

.table {
  border-collapse: collapse;

  @media (max-width: 1100px) {
    overflow-x: scroll;
  }
}

.spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50%;
  margin-top: 20px;
}

.icon {
  min-width: 42px;
}

.avatar {
  min-width: 42px;
  height: 42px;
  background-color: var(--lighter-grey);
  border: none;
  border-radius: 16px;
  outline: unset;
  cursor: pointer;

  &__wrapper {
    display: flex;
    align-items: center;

    & > :first-of-type {
      margin-right: 20px;
    }
  }

  @media (max-width: 560px) {
    margin-right: unset;
  }
}

.requests__buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
}

.decline__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: unset;
  max-width: 52px;
  padding: 0;
}
