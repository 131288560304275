.mini-plan {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 22px;
  background: white;
  border-radius: 24px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 968px) {
    flex-direction: column;
    margin-bottom: 24px;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: start;

    @media (max-width: 968px) {
      margin-bottom: 24px;
    }
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    min-width: 56px;
    height: 56px;
    margin-right: 16px;
    background: #f9f9f9;
    border-radius: 50%;
  }

  &__button-box {
    display: flex;

    :first-child {
      margin-right: 13px;
    }
  }

  &__button {
    max-width: 154px;
  }
}
