
.title {
  overflow: hidden;
  font-size: 30px;
  text-align: center;

  p {
    display: inline-block;
    color: var(--dark-grey);
    font-size: 16px;
    vertical-align: middle;
  }

  &::before {
    position: relative;
    left: -14px;
    display: inline-block;
    width: 100%;
    height: 2px;
    margin-left: -100%;
    vertical-align: middle;
    background-color: var(--dark-grey);
    content: "";
  }

  &::after {
    position: relative;
    right: -14px;
    display: inline-block;
    width: 100%;
    height: 2px;
    margin-right: -100%;
    vertical-align: middle;
    background-color: var(--dark-grey);
    content: "";
  }
}
