.user-info-block {
  justify-content: space-between;
  margin-bottom: 40px;

  @media (max-width: 1350px) {
    flex-direction: column;
  }

  @media (max-width: 560px) {
    margin-bottom: 20px;
  }

  &__gray-text {
    color: var(--dark-grey);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;

    @media (max-width: 560px) {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  &__left-block {
    &__avatar {
      min-width: 56px;
      height: 56px;
      margin-right: 20px;
      background-color: var(--lighter-grey);
      border-radius: 16px;

      @media (max-width: 580px) {
        min-width: 48px;
        height: 48px;
        margin-right: 16px;
      }
    }

    &__name {
      margin-bottom: 12px;
      color: var(--black);
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.01em;

      @media (max-width: 560px) {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
    }

    @media (max-width: 1350px) {
      margin-bottom: 20px;
    }
  }

  &__right-block {
    &__register-date {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.01em;

      @media (max-width: 560px) {
        padding-top: 4px;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
    }

    @media (max-width: 560px) {
      flex-direction: column;
      align-items: flex-start;
      padding-top: 4px;
    }
  }
}
