.nutrition-plan {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__body {
    margin-top: 40px;
  }
}

.empty-state {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    padding: 280px 0;
    background: var(--white);
    border-radius: 16px;
    box-shadow: 0 8px 52px #f3f3f3;

    @media (max-width: 768px) {
      padding: 120px 0;
    }
  }

  &__title {
    margin-top: 45px;
    color: #262626;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
  }

  &__subtitle {
    margin-top: 16px;
    color: var(--dark-grey);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }
}

.user-nutrition-plan-tab {
  &__waiting {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 310px;
  }
}
