.recommendations {
  display: flex;
  flex-direction: column;
  margin-bottom: 28px;

  &__item {
    &:not(:last-child) {
      margin-bottom: 28px;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 172px;
    padding: 36px;
    border-radius: 16px;
    box-shadow: 0 8px 52px #f3f3f3;

    &_blue {
      color: var(--white);
      background: var(--background-primary);
      border-radius: 16px;
    }

    &_light {
      color: var(--secondary-black);
      background: var(--white);
    }

    &_left-border {
      border-left: 4px solid var(--red);
      border-radius: 2px 16px 16px 2px;
    }

    @media (max-width: 600px) {
      flex-wrap: wrap;
      min-height: 169px;
      padding: 16px 16px 20px;
    }
  }

  &__content {
    width: 100%;

    &-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 16px;

      @media (max-width: 600px) {
        flex-wrap: wrap;
      }
    }
  }

  &__score {
    margin-top: 15px;
  }

  &__title {
    &_blue {
      color: var(--white);
    }

    &_light {
      color: var(--secondary-black);
    }

    @media (max-width: 760px) {
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__text {
    max-width: 590px;
    margin-right: 20px;
    font-weight: normal;
    font-size: 16px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: -0.01em;

    @media (max-width: 760px) {
      &_blue {
        color: var(--white);
      }

      &_light {
        color: var(--dark-grey);
      }

      color: var(--dark-grey);
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__link {
    &_blue {
      button {
        color: var(--white) !important;
      }

      @media (max-width: 600px) {
        svg {
          path {
            fill: var(--white) !important;
          }
        }
      }
    }

    svg {
      display: none;
    }

    @media (max-width: 600px) {
      display: flex;
      align-items: center;

      svg {
        display: flex;
        width: 15px;
        height: 12px;
        margin-left: 12px;

        path {
          transition: 0.2s;
          fill: var(--background-primary);
        }
      }

      &:hover {
        button {
          color: var(--dark-grey);
        }

        svg path {
          fill: var(--dark-grey);
        }
      }
    }
  }

  &__buttons {
    align-self: flex-end;
    margin-left: 10%;

    @media (max-width: 760px) {
      margin-top: 10px;
    }

    @media (max-width: 600px) {
      margin-left: unset;

      button {
        width: auto !important;
        min-width: auto;
        height: unset;
        margin: 0;
        padding: 0;
        color: var(--background-primary);
        background: unset;
        transition: color 0.2s;

        &:hover {
          background: unset;
        }
      }
    }
  }
}
