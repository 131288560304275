.diet {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 32px;

  @media (max-width: 1210px) {
    display: block;
  }

  &__title {
    margin-bottom: 24px;
    color: var(--black);
    font-weight: bold;
  }

  &__text_grey {
    max-width: 196px;
    margin-bottom: 8px;
    color: var(--dark-grey);
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
  }

  &__text {
    max-width: 360px;
    margin-bottom: 20px;
    letter-spacing: -0.01em;
  }

  &__left {
    width: 50%;
  }

  &__right {
    width: 50%;
  }
}
