.empty {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  height: 100%;
  max-height: 670px;
  margin-top: 0;
  padding: 48px 48px;
  background: var(--white);
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 768px) {
    margin-top: 32px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    width: 64px;
    height: 64px;
    margin-bottom: 20px;
  }

  &__subtitle {
    margin-top: 20px;
    margin-bottom: 24px;
    color: var(--dark-grey);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }

  @media (max-width: 500px) {
    padding: 0;
    box-shadow: unset;
  }
}
