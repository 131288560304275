.expert {
  width: 100%;
  max-width: 340px;
  height: 768px;
  padding: 42px 32px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 970px) {
    width: auto;
    max-width: 250px;
    height: auto;
  }

  @media (max-width: 768px) {
    max-width: unset;
  }

  @media (max-width: 640px) {
    border-radius: 0;
  }

  &__username_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
    column-gap: 4px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;

    &__info-avatar {
      width: 160px;
      height: 160px;
      margin-bottom: 24px;
      background: var(--dark-grey);
      border-radius: 50%;
    }

    &__info-name {
      max-width: 300px;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: -0.01em;
      text-align: center;
      word-wrap: break-word;

      @media (max-width: 970px) {
        max-width: 200px;
      }

      @media (max-width: 768px) {
        max-width: 90vw;
      }
    }

    &__info-year {
      font-weight: 500;
      font-size: 16px;
      letter-spacing: -0.01em;
    }
  }

  &__description {
    @media (max-width: 640px) {
      display: grid;
      grid-template-rows: auto;
      // grid-template-columns: 1fr 1fr;
      grid-template-columns: repeat(2, 1fr);
      gap: 24px;
    }
  }

  &__paragraph {
    @media (max-width: 640px) {
      &:last-child {
        grid-column: 1 / 3;
      }
    }

    @media (min-width: 640px) {
      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }

  &__about {
    max-height: 205px;
    overflow-y: auto;
    word-wrap: break-word;
  }

  &__text_grey {
    margin-bottom: 12px;
    color: var(--dark-grey);
  }
}

.expert__spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.expert__spesialization {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
