.sidebar__link {
  position: relative;
  display: block;
  color: var(--dark-grey);
  font-weight: bold;
  font-size: 20px;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.01em;

  &:hover {
    color: var(--black);
  }

  &:not(:last-child) {
    margin-bottom: 48px;

    @media (max-width: 768px) {
      margin-bottom: 24px;
    }
  }

  @media (min-width: 767px) {
    &::after {
      position: absolute;
      right: 0;
      bottom: -24px;
      left: 0;
      width: 100%;
      height: 1px;
      border-top: 1px solid var(--light-grey);
      content: '';
    }

    &:last-child::after {
      display: none;
    }
  }

  &--active {
    color: var(--black);
  }

  &-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-arrow {
    @media (min-width: 767px) {
      display: none;
    }
  }
}
