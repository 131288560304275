.container {
  width: 100%;
  height: 100%;
  padding: 32px 32px 12px 32px;
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 760px) {
    padding: 0 0 16px 0;
    box-shadow: unset;
  }
}

.analysis {
  &-kind {
    margin-bottom: 24px;
  }

  &__header {
    margin-bottom: 28px;
    padding-bottom: 28px;
    border-bottom: 2px solid var(--light-grey);

    @media (max-width: 760px) {
      display: none;
    }

    &--mobile {
      display: none;
      border-bottom: 2px solid var(--light-grey);

      @media (max-width: 760px) {
        display: block;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }
    }
  }

  &-date {
    @media (max-width: 760px) {
      margin-bottom: 12px;
    }
  }

  &__label {
    color: var(--dark-grey);
  }

  &__user-data {
    @media (max-width: 760px) {
      justify-content: space-between;
    }
  }

  &__user-name {
    margin-right: 32px;
    margin-bottom: 16px;

    @media (max-width: 576px) {
      margin-bottom: 12px;
    }
  }

  &__user-age {
    margin-right: 32px;

    @media (max-width: 760px) {
      margin-bottom: 0;
    }
  }

  &__user-gender {
    margin-right: 32px;

    @media (max-width: 760px) {
      margin-bottom: 12px;
    }
  }

  &__user-biologic-age {
    margin-top: auto;

    @media (max-width: 760px) {
      margin-top: auto;
    }
  }

  &__actions {
    display: flex;

    & .print-button {
      @media (max-width: 760px) {
        display: none;
      }
    }

    @media (max-width: 760px) {
      svg {
        width: 10px;
        height: 10px;
      }
    }

    & button {
      margin-right: 24px;

      @media (max-width: 760px) {
        margin-right: 12px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.biomarkers {
  @media (max-width: 760px) {
    font-size: 14px;
    line-height: 18px;
  }

  &__header {
    margin-bottom: 4px;
    color: var(--dark-grey);

    @media (min-width: 768px) {
      text-align: center;
    }

    &-name {
      text-align: start;
    }
  }
}

.generate-button {
  max-width: 235px;

  @media (max-width: 768px) {
    position: sticky;
    bottom: 80px;
    max-width: unset;
    margin-top: auto;
  }
}
