.tooltip {
  > span {
    text-align: left !important;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 288px;
  padding: 8px 24px !important;
  color: var(--white);
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: -0.01em !important;
  background: var(--background-black) !important;
  border-radius: 8px !important;
  opacity: 1 !important;

  &::after {
    border-top-color: var(--background-black) !important;
  }

  @media (max-width: 560px) {
    &::after {
      border-top-color: transparent !important;
    }
  }
}
