.container {
  position: relative;
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
  font-size: 14px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  width: 0;
  height: 0;
  cursor: pointer;
  opacity: 0;
}

.checkmark {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: white;
  border: 2px solid var(--grey);
  border-radius: 6px;
  transition: all 0.3s ease;

  &:hover {
    border: 2px solid var(--background-primary-light);
  }
}

.container input:checked ~ .checkmark {
  background-color: var(--background-primary);
  border-color: var(--background-primary);
  border-radius: 6px;
  transition: all 0.3s ease;
}
