.wrapper {
  padding: 32px;
  background: var(--white);
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 576px) {
    padding: 0 0 16px;
    background: none;
    box-shadow: none;
  }
}
