.banner {
  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 100%;
    margin-top: 48px;
    padding: 26px 24px 24px 32px;
    background-color: var(--yellow);
    border-radius: 24px;

    @media (max-width: 768px) {
      margin-top: 24px;
      padding: 16px;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 48px;
    height: 48px;
    margin-right: 24px;

    svg > path {
      stroke: var(--white);
    }

    @media (max-width: 768px) {
      margin-right: 12px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
    max-width: 400px;
    color: var(--light-grey);
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;

    @media (max-width: 768px) {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
    }

    @media (max-width: 576px) {
      max-width: 182px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    background: rgba(255, 255, 255, 0.15);
    border-radius: 16px;

    svg > g > path {
      fill: var(--white);
    }
  }
}
