.subscribe-card {
  &__link {
    margin-top: 20px;
  }

  &__container {
    display: flex;
    padding: 23px 24px;
    background: var(--white);
    border-radius: 24px;
    box-shadow: 0 8px 52px #f3f3f3;
    cursor: pointer;
  }

  &__icon {
    display: flex;
    align-items: center;
    margin-right: 28px;
  }

  &__content {
    max-width: 152px;
    margin-right: 30px;
    color: var(--secondary-black);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    background-color: var(--background-yellow);
    border-radius: 16px;
  }

  &__button-icon {
    g > path {
      fill: var(--yellow);
    }
  }
}
