.letter {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  padding-top: 24px;
  //margin-bottom: 24px;
  border-radius: 24px;

  &__content {
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: 100%;
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 56px;
    min-height: 56px;
    margin-right: 16px;
    background: #f9f9f9;
    border-radius: 50%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__info-subtitle {
    color: var(--dark-grey);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    white-space: pre-line;
    overflow-wrap: anywhere;
  }

  &__info-title {
    font-weight: 700;
    font-size: 16px;

    @media (max-width: 860px) {
      font-size: 16px;
    }
  }

  &__info-date {
    margin-left: 8px;
    color: var(--dark-grey);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  &__container {
    margin-bottom: 12px;
  }

  &__has-chat {
    margin-bottom: 12px;
  }

  &__right {
    justify-content: end;
    margin-left: auto;

    .letter {
      &__content {
        justify-content: flex-end;

        & .current-file {
          justify-content: flex-end;
        }
      }

      &__circle {
        order: 1;
        margin-right: 16px;
        margin-left: 16px;
      }

      &__container {
        display: flex;
        margin-left: auto;
      }

      &__info-title {
        order: 1;
      }

      &__info-subtitle {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        max-width: 75%;
        text-align: left;
      }

      &__info-date {
        margin-right: 8px;
        margin-left: 0;
      }
    }
  }

  &__current {
    padding-left: 20px;
    border-left: 2px solid #646efa;
  }
}

.response {
  margin-top: 13px;
  background: transparent;
  border: none;
  outline: none;
  opacity: 0;
}

.letter:hover .response {
  opacity: 1;
  transition: 1s;
}
