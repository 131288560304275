.button {
  display: flex;
  display: -webkit-flex;
  flex-grow: 0;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  width: 100%;
  min-width: 177px;
  height: 52px;
  padding: 16px 32px;
  font-weight: bold;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.01em;
  border: 0;
  border-radius: 16px;
  cursor: pointer;
  transition: all 0.1s;

  &-primary {
    color: var(--white);
    background: var(--background-secondary);

    &:hover {
      background: var(--background-secondary-dark);
    }

    &:focus {
      background: var(--background-secondary-dark);
    }

    &:disabled {
      color: var(--white);
      background: var(--background-secondary-light);
    }
  }

  &-secondary {
    color: var(--secondary-black);
    background: var(--light-grey);

    &:hover {
      background: var(--grey);
    }

    &:focus {
      background: var(--grey);
    }

    &:disabled {
      color: var(--grey);
      background: var(--light-grey);
    }
  }

  &-delete {
    color: var(--white);
    background: var(--red);

    &:hover {
      background: var(--dark-red);
    }

    &:focus {
      background: var(--dark-red);
    }

    &:disabled {
      background: var(--light-red);
    }
  }

  &-message {
    color: var(--white);
    background: var(--blue);

    &:hover {
      background: var(--dark-blue);
    }

    &:focus {
      background: var(--dark-blue);
    }

    &:disabled {
      background: var(--light-blue);
    }
  }

  &-square {
    background: var(--light-grey);

    &:hover {
      background: var(--grey);
    }
  }

  &-subscribe-primary {
    color: var(--white);
    background: var(--yellow);
  }

  &-subscribe-secondary {
    color: var(--yellow);
    background: var(--background-yellow);
  }

  @media (max-width: 640px) {
    height: 46px;
    font-size: 14px;
    line-height: 18px;
    border-radius: 14px;
  }
}
