.goal {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 50%;
  margin-bottom: 32px;

  &:nth-child(2n + 1) {
    padding-right: 16px;
  }

  @media (max-width: 1100px) {
    width: 100%;
  }

  &__text {
    max-width: 394px;
  }

  &__avatar {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    min-width: 52px;
    height: 52px;
    margin-right: 20px;
    background: var(--light-grey);
    border: none;
    border-radius: 16px;

    &:hover {
      &__avatar_delete {
        display: none;
      }
    }
  }

  &__avatar_delete {
    position: absolute;
    left: 0;
    display: none;
    align-items: center;
    justify-content: center;
    width: 52px;
    min-width: 52px;
    height: 52px;
    margin-right: 20px;
    background: var(--grey);
    border-radius: 16px;
  }

  &__avatar:hover &__avatar_delete {
    display: flex;
  }

  &__button_delete {
    margin-right: 10px;
    margin-left: 10px;
    background: white;
    border: none;
    outline: none;
  }
}
