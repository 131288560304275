.sidebar {
  flex: 4;
  margin-right: 24px;
  padding: 32px;
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 768px) {
    flex: 1;
    width: 100%;
    margin-right: 0;
    margin-bottom: 60px;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
  }
}
