.recommendations-list {
  &__card {
    &:not(:last-child) {
      margin-bottom: 28px;

      @media (max-width: 600px) {
        margin-bottom: 20px;
      }
    }
  }
}
