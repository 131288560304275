.rbc-event {
  &__MEETING {
    background: #faf6ee;
    border-left: 5px solid #f3b63e;
    border-radius: 4px 0 0 4px;
  }

  &__MEETING::before {
    width: 10px;
    color: black;
    content: '';
  }
}

.rbc-event--MEETING {
  color: #404040;
  background: #eef1fa;
  border-left: 5px solid #646efa;
  border-radius: 4px 0 0 4px;

  &__dot {
    width: 8px;
    height: 8px;
    margin: 5px;
    background: #646efa;
    border-radius: 50%;
  }
}

.rbc-event--VIDEOCALL {
  color: #404040;
  background: #faf6ee;
  border-left: 5px solid #f3b63e;
  border-radius: 4px 0 0 4px;

  &__dot {
    width: 8px;
    height: 8px;
    margin: 5px;
    background: #f3b63e;
    border-radius: 50%;
  }
}

.rbc-event--CALL {
  color: #404040;
  background: #eefaf6;
  border-left: 5px solid #59cda4;
  border-radius: 4px 0 0 4px;

  &__dot {
    width: 8px;
    height: 8px;
    margin: 5px;
    background: #59cda4;
    border-radius: 50%;
  }
}

.rbc-events-container {
  display: grid;
  grid-template-rows: repeat(24, 40px);
}

.toolbar {
  width: 116px;
  height: 40px;
  margin-right: 20px;
  background: var(--light-grey);
  border: none;
  border-radius: 12px;

  @media (max-width: 968px) {
    width: 96px;
    height: 36px;
  }

  @media (max-width: 720px) {
    width: 100%;
    min-width: 96px;
    height: 36px;
  }

  @media (max-width: 370px) {
    margin-right: 5px;
  }

  &__nav {
    display: flex;
  }

  &__calendar {
    //position: absolute;
    //top: 50%;
    //left: 50%;
    //transform: translate(-50%, -50%);
    //z-index: 999999;
  }

  &__title-button {
    background: transparent;
    border: none;
    outline: none;
  }

  &__title {
    font-size: 32px;
    white-space: pre;
    text-transform: capitalize;

    @media (max-width: 650px) {
      font-size: 24px;
    }
  }

  &__container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30px;

    @media (max-width: 968px) {
      justify-content: space-between;
      padding-right: 24px;
      padding-left: 24px;
    }

    @media (max-width: 720px) {
      flex-direction: column;
      gap: 20px;
      margin-bottom: 24px;
      padding-right: 24px;
      padding-left: 24px;
    }
  }

  &__navigation {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__navigation-buttons {
    display: flex;
    align-items: center;
  }

  &__date {
    display: flex;
    justify-content: space-between;
  }

  &__arrows {
    display: flex;
    align-items: center;
    margin-left: 16px;
  }

  &__arrow {
    margin-right: 25px;
    background: transparent;
    border: none;
  }

  &__active {
    color: white;
    background-color: #646efa;
  }
}

.toolbar:active {
  width: 116px;
  height: 40px;
  color: white;
  background-color: #646efa;
  border-radius: 12px;

  @media (max-width: 968px) {
    width: 96px;
    height: 36px;
  }

  @media (max-width: 720px) {
    width: 100%;
    min-width: 96px;
    height: 36px;
  }
}

.toolbar:last-child {
  width: 116px;
  height: 40px;
  margin-right: 0;
  border-radius: 12px;

  @media (max-width: 968px) {
    width: 96px;
    height: 36px;
  }

  @media (max-width: 720px) {
    width: 100%;
    min-width: 96px;
    height: 36px;
  }
}

.calendars {
  display: flex;
  align-items: start;
  justify-content: space-between;
  max-width: 1440px;
  margin-top: 60px;
  padding-right: 60px;
  padding-bottom: 60px;
  padding-left: 60px;

  @media (max-width: 968px) {
    margin-top: 38px;
    padding-right: 0;
    padding-left: 0;
  }

  &__button {
    background: transparent;
    border: none;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
