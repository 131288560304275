.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content {
  &-container {
    flex-grow: 1;
    width: 95%;
    max-width: 932px;
    height: 100%;
    margin: 0 auto;
    padding: 40px 0;

    @media (max-width: 760px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      padding: 14px 24px 24px 24px;
    }
  }

  &-back-options {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    &__back-text {
      margin-right: auto;
      margin-left: 16px;

      @media (max-width: 760px) {
        margin-left: 0;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
      }

      &_above-title {
        @media (max-width: 760px) {
          display: none;
        }
      }
    }

    @media (max-width: 760px) {
      flex-direction: column;
      align-items: flex-start;
    }

    &_above-title {
      @media (max-width: 760px) {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
      }
    }
  }

  &__additional {
    display: flex;
    align-items: center;

    & > * {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }

    @media (max-width: 760px) {
      justify-content: space-between;
      width: 100%;
      margin-top: 14px;

      & > * {
        &:not(:last-child) {
          margin-right: 12px;
        }
      }
    }

    &_above-title {
      @media (max-width: 760px) {
        width: auto;
        max-width: 75%;
        margin-top: unset;
      }
    }
  }
}
