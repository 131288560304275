.password-input {
  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background-color: var(--light-grey);
    border: 1px solid var(--light-grey);
    border-radius: 16px;
    transition: all 0.2s;

    &_error {
      border: 1px solid var(--red);
    }

    &:focus-within {
      border: 1px solid var(--grey);
    }

    @media (max-width: 640px) {
      padding: 10px;
      border-radius: 14px;
    }
  }

  &__input {
    width: 100%;
    color: var(--secondary-black);
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
    background-color: var(--light-grey);
    border: none;
    outline: none;

    &_error {
      color: var(--red);
    }

    &::placeholder {
      color: var(--dark-grey);
      font-weight: 500;
      font-size: 16px;
      font-style: normal;
      line-height: 20px;

      @media (max-width: 640px) {
        font-size: 14px;
        line-height: 20px;
      }
    }

    @media (max-width: 640px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__icon {
    display: flex;
    margin: 0;
    padding: 0;
    padding-left: 16px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    svg path {
      transition: 0.2s;
      fill: var(--dark-grey);
    }

    &:hover {
      svg path {
        fill: var(--secondary-black);
      }
    }
  }
}
