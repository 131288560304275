.recommendation {
  padding: 32px;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  &__important {
    padding: 24px 32px 24px 28px;
    background: rgba(251, 123, 123, 0.05);
    border-left: 4px solid var(--red);
    border-radius: 2px 16px 16px 2px;

    &-title {
      margin-bottom: 12px;
      color: var(--red);
      font-weight: bold;
      font-size: 24px;
      font-style: normal;
      line-height: 32px;

      @media (max-width: 640px) {
        margin-bottom: 8px;
        font-size: 20px;
        line-height: 24px;
      }
    }

    &__text {
      color: var(--secondary-black);
      font-weight: normal;
      font-size: 16px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: -0.01em;

      @media (max-width: 640px) {
        font-size: 14px;
      }
    }

    @media (max-width: 640px) {
      padding: 12px;
      border-radius: 2px 12px 12px 2px;
    }
  }

  &__scale {
    display: flex;
    flex-wrap: wrap;

    &-text {
      margin-right: 12px;
      white-space: nowrap;

      @media (max-width: 600px) {
        width: 100%;
        margin-bottom: 18px;
      }
    }
  }

  &__content {
    p {
      margin-bottom: 16px;
    }

    @media (max-width: 640px) {
      & li {
        margin-bottom: 8px !important;
        font-size: 14px !important;
      }
    }
  }

  @media (max-width: 640px) {
    margin-bottom: 36px;
    padding: 0;
    box-shadow: unset;
  }
}

.chart {
  &-wrapper {
    margin-top: 64px;
  }

  &__selectors {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 32px;
  }

  &__title {
    @media (max-width: 6000px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__date-selectors {
    display: flex;
    align-items: center;
  }

  &__date-range {
    display: flex;
    align-items: center;

    @media (max-width: 760px) {
      &__date-range {
        justify-content: space-between;
      }
    }
  }

  &__range {
    &-title {
      margin-right: 24px;
      color: var(--dark-grey);
    }

    &-divider {
      margin-right: 16px;
      margin-left: 16px;
    }

    @media (max-width: 760px) {
      &-title {
        display: none;
      }

      &-divider {
        margin-right: 7px;
        margin-left: 7px;
      }
    }
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;

    &__norm {
      color: var(--dark-grey);

      &-square {
        width: 24px;
        min-width: 24px;
        height: 24px;
        margin-right: 8px;
        margin-left: 10px;
        background: var(--background-secondary-lighter);
        border-radius: 8px;
      }

      &-zone {
        display: flex;
        align-items: center;
      }

      &-value {
        width: 100%;
      }
    }
  }
}
