.footer-mobile {
  position: sticky;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  width: 100%;
  height: 68px;
  margin-top: auto;
  padding-right: 65px;
  padding-left: 65px;
  background: var(--lighter-grey);

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    text-align: center;
    text-decoration: none;
    background: var(--background-secondary);
    border: none;
    border-radius: 50%;
    outline: none;
    transition: background 0.2s;

    &_disabled {
      background: var(--yellow);
    }

    &_disabled_expert {
      background: var(--background-secondary);
      opacity: 0.6;
    }

    @media (min-width: 768px) {
      &:hover {
        background: var(--background-primary);
      }
    }
  }
}
