.form {
  &__container {
    margin-top: 48px;

    @media (max-width: 968px) {
      margin-top: 32px;
      overflow-y: scroll;
    }
  }

  &__fields {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;

    @media (max-width: 968px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  &__field {
    width: 100%;
    max-width: 362px;

    @media (max-width: 968px) {
      max-width: 100%;
      margin-bottom: 24px;
    }
  }

  &__button {
    &__container {
      display: flex;
      gap: 16px;

      @media (max-width: 530px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
      }
    }
  }
}

.button {
  display: flex;
  justify-content: flex-end;
}

.save_button {
  display: flex;
  justify-content: center;
  max-width: 200px;

  @media (max-width: 968px) {
    max-width: 100%;
    margin-top: 12px;
  }
}
