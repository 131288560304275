.subtitle {
  margin-top: 10px;
  margin-bottom: 28px;
  color: #b4b4b4;

  @media (max-width: 576px) {
    margin-bottom: 32px;
  }
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
  gap: 24px;
}

.force-wrap {
  width: 50%;
  text-align: start;
}
