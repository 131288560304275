.letter-mobile {
  display: flex;
  width: 100%;
  height: max-content;
  padding-bottom: 20px;
  word-break: break-word;

  &:first-child {
    padding-top: 20px;
  }

  &:last-child {
    padding-bottom: 20px;
  }

  &__container {
    width: auto;
    padding: 11px 11px;
    white-space: pre-line;
    overflow-wrap: anywhere;
    background: #f7f7f7;
    border-radius: 10px 10px 10px 0;
  }

  &__text {
    margin-top: 4px;
    margin-left: 8px;
    color: var(--black);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  &__text_date {
    margin-top: 4px;
    margin-left: 8px;
    color: var(--dark-grey);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  &__right {
    align-items: end;
    justify-content: end;
    padding: 11px 11px;
    background: #d9e5ff;
    border-radius: 10px 10px 0 10px;
  }

  &__position_right {
    margin-right: 4px;
    margin-left: auto;
    padding-left: 12px;
  }

  &__position__left {
    padding-right: 12px;
  }
}
