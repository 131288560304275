.plan {
  width: 100%;
  padding: 48px;
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 762px) {
    padding: 0;
    box-shadow: none;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
  }

  &__button {
    display: flex;
    margin-left: 8px;

    @media (max-width: 1060px) {
      display: none;
    }
  }

  &__button_mobile {
    display: none;

    @media (max-width: 1060px) {
      display: flex;
      margin-bottom: 32px;
    }
  }

  &__button_change {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    min-width: 52px;
    height: 52px;
    margin-right: 20px;
    background: var(--grey);
    border: none;
    border-radius: 16px;
    outline: none;
  }

  &__waiting {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 310px;
  }
}

.goals {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1100px) {
    flex-direction: column;
  }

  &__title {
    margin-bottom: 36px;
  }

  &__goal {
    display: flex;
    align-items: center;
    justify-content: start;
    width: 50%;
    margin-bottom: 32px;
  }

  &__text {
    max-width: 394px;
  }

  &__avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    min-width: 52px;
    height: 52px;
    margin-right: 20px;
    background: var(--grey);
    border-radius: 16px;
  }
}

.restrictions {
  width: 100%;

  &__container {
    display: flex;
    width: 100%;

    @media (max-width: 1210px) {
      display: none;
    }
  }

  &__on {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 44px;
    background: #eefaf6;
    border-radius: 12px 0 0 12px;
  }

  &__on-text {
    color: #59cda4;
  }

  &__off {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50%;
    height: 44px;
    background: #fff4f4;
    border-radius: 0 12px 12px 0;
  }

  &__off-text {
    color: #fb7b7b;
  }
}

.diet {
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid var(--light-grey);

  @media (max-width: 1210px) {
    display: block;
  }

  &__block {
    margin-bottom: 16px;
  }

  &__title {
    margin-bottom: 24px;
    color: var(--black);
    font-weight: bold;
  }

  &__text_grey {
    max-width: 192px;
    margin-bottom: 8px;
    color: var(--dark-grey);
    line-height: 18px;
    letter-spacing: -0.01em;
  }

  &__text {
    max-width: 360px;
    margin-bottom: 20px;
    letter-spacing: -0.01em;
  }

  &__left {
    width: 50%;
    padding-top: 32px;
    padding-right: 40px;
    overflow: hidden;
    border-right: 1px solid var(--light-grey);

    @media (max-width: 1210px) {
      width: 100%;
      margin-top: 24px;
      padding-top: 0;
      padding-right: 0;
      border: none;
    }
  }

  &__right {
    width: 50%;
    padding-top: 32px;
    padding-left: 40px;
    overflow: hidden;

    @media (max-width: 1210px) {
      width: 100%;
      margin-top: 24px;
      padding-top: 0;
      padding-left: 0;
    }
  }
}
