.top-bar-wrapper {
  align-items: center;
  justify-content: space-between;
  padding: 32px 60px 0;

  @media (max-width: 860px) {
    padding-right: 24px;
    padding-left: 24px;
  }

  @media (max-width: 760px) {
    display: none;
  }
}

.top-bar-left-block {
  align-items: center;
}

.top-bar-title {
  padding-left: 36px;
  font-weight: 800;
  font-size: 32px;
  line-height: 42px;

  @media (max-width: 999px) {
    font-size: 24px;
    line-height: 32px;
  }
}

.top-bar-buttons-wrapper {
  & button {
    margin-right: 24px;
  }

  & button:last-child {
    margin-right: 0;
  }
}

.top__bar__button {
  white-space: nowrap;
}
