.table {
  display: flex;
  flex-direction: column;
}

.description {
  margin-bottom: 12px;

  p {
    margin-bottom: 16px;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  &__item {
    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }

    &:nth-child(2) {
      margin: 0 5px;
    }

    color: var(--dark-grey);
  }
}

.body {
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  &__item {
    &:first-child {
      margin-right: 5px;
    }

    &:last-child {
      margin-left: 5px;
    }

    &:nth-child(2) {
      margin: 0 5px;
    }

    &:not(:last-child) {
      padding-right: 32px;

      @media (max-width: 640px) {
        padding-right: 16px;
      }
    }

    &_int-name {
      @media (max-width: 640px) {
        display: none;
      }
    }
  }
}

.subtitle {
  margin-bottom: 23px;
}
