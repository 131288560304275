.profile-settings-tab {
  &__heading {
    display: none;

    @media (max-width: 760px) {
      display: block;
      margin-bottom: 29px;
    }
  }

  &__title {
    margin-bottom: 30px;
    color: var(--black);
    font-weight: bold;
    font-size: 20px;

    @media (max-width: 760px) {
      font-size: 18px;
    }
  }
}

.mail-address-section {
  &__title {
    margin-bottom: 28px;
    font-size: 20px;
    line-height: 24px;

    &_mobile {
      display: none;
    }

    @media (max-width: 760px) {
      font-size: 24px;
      line-height: 32px;

      &_mobile {
        display: inline-block;
      }
    }
  }

  &__mobile-subtitle {
    display: none;

    @media (max-width: 760px) {
      display: block;
      margin-bottom: 28px;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__subtitle {
    margin-bottom: 12px;
  }

  &__button {
    width: 200px;

    @media (max-width: 1100px) {
      margin-top: 20px;
    }

    @media (max-width: 760px) {
      margin-top: 0;
    }

    @media (max-width: 580px) {
      width: 46px;
      min-width: 46px;
      height: 46px;
      margin-top: 0;
      padding: 0;
    }
  }

  &__form {
    display: flex;

    input {
      margin-right: 24px;
    }

    @media (max-width: 1100px) {
      flex-wrap: wrap;

      input {
        //margin-right: unset;
      }
    }

    @media (max-width: 760px) {
      flex-wrap: nowrap;
      justify-content: space-between;
    }
  }
}

.password-section {
  &__title {
    margin-bottom: 28px;

    @media (max-width: 760px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__inputs {
    margin-bottom: 4px;
  }

  &__button {
    width: 203px;

    @media (max-width: 640px) {
      width: 100%;
    }
  }

  &__custom-input {
    height: 52px;
  }

  &__input {
    max-width: 414px;
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 40px;
    }

    &-error {
      color: var(--red);
    }

    @media (max-width: 640px) {
      max-width: unset;
    }
  }

  &__button-container {
    @media (max-width: 760px) {
      gap: 8px;
      justify-content: flex-start;
    }

    @media (max-width: 640px) {
      flex-direction: column;
      gap: 20px;
    }
  }
}

.update-email-step {
  padding: 16px 0 40px;

  &__text {
    margin-bottom: 40px;
    color: var(--dark-grey);

    @media (max-width: 540px) {
      margin-bottom: 37px;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__text-email {
    font-weight: bold;
  }

  &__spinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__input {
    &-error {
      color: var(--red);
    }
  }

  &__repeat-code {
    color: var(--dark-grey);

    @media (max-width: 540px) {
      max-width: 180px;
      margin: 0 auto;
      text-align: center;
    }
  }

  &__repeat-code-btn {
    padding: 0;
    color: var(--background-primary);
    background: none;
    border: none;
    cursor: pointer;
    appearance: none;

    @media (max-width: 540px) {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 46px;
      padding: 16px 32px;
      color: var(--white);
      font-weight: bold;
      font-size: 14px;
      font-style: normal;
      line-height: 18px;
      line-height: 20px;
      letter-spacing: -0.01em;
      text-align: center;
      background: var(--background-secondary);
      border: 0;
      border-radius: 16px;
      cursor: pointer;

      &:hover {
        background: var(--background-secondary-dark);
      }

      &:focus {
        background: var(--background-secondary-dark);
      }

      &:disabled {
        background: var(--background-secondary-light);
      }
    }
  }

  @media (max-width: 540px) {
    padding-bottom: 23px;
  }
}

.notification-section {
  &__content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 36px;

    &__title {
      color: #404040;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.01em;
    }
  }
}

.separator {
  margin: 32px 0 32px 0;
  border: 1px solid #f7f7f7;
}

.dropdown-small {
  width: 100% !important;
  min-width: 191px;
  max-width: 191px;

  @media (max-width: 760px) {
    min-width: 151px;
  }
}

.modal-email {
  &__text {
    width: 100%;
  }
}
