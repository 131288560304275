.current-file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 14px;
  margin-bottom: 14px;
  padding-right: 12px;
  padding-left: 12px;

  &__container {
    display: flex;
    align-items: center;
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
  }

  &__text {
    margin-bottom: 4px;
    color: var(--black);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &__text_grey {
    color: var(--dark-grey);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  &__button {
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
  }
}
