.list-message {
  position: relative;
  width: auto;
  min-width: 340px;
  max-width: 340px;
  height: 768px;
  overflow-y: auto;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 976px) {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: auto;
    min-width: 300px;
  }

  @media (max-width: 760px) {
    width: 100%;
    max-width: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  form {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    padding: 24px 20px 20px;
    background: var(--white);
  }
}

.form {
  position: relative;
  display: flex;
  width: 100%;

  &__add-chat {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 52px;
    min-height: 52px;
    margin-left: 5px;
    background-color: var(--light-grey);
    border: none;
    border-radius: 16px;
    cursor: pointer;
  }

  &__container {
    align-items: center;
    justify-content: center;
  }

  &__submit {
    position: absolute;
    top: 16px;
    left: 18px;
    background: transparent;
    border: none;
    cursor: pointer;
  }
}

.letter {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  padding-right: 20px;
  padding-left: 20px;
  background: transparent;
  border: none;

  @media (max-width: 968px) {
    width: auto;
    margin-bottom: 0;
  }

  &__active {
    border-left: 2px solid #646efa;
  }

  &__content {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 308px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--light-grey);
  }

  &__notification {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    color: var(--white);
    font-size: 12px;
    background: var(--red);
    border-radius: 50%;
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    min-width: 56px;
    height: 56px;
    min-height: 56px;
    margin-right: 16px;
    background: #f9f9f9;
    border-radius: 50%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__info-subtitle {
    color: var(--dark-grey);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  &__info-title {
    color: var(--black);
    font-weight: 700;
    font-size: 16px;

    @media (max-width: 860px) {
      font-size: 16px;
    }
  }

  &__info-date {
    margin-top: 4px;
    margin-left: 8px;
    color: var(--dark-grey);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  &__right {
    justify-content: end;
  }

  &__current {
    padding-left: 20px;
    border-left: 2px solid #646efa;
  }
}

.response {
  margin-top: 13px;
  background: transparent;
  border: none;
  outline: none;
  opacity: 0;
}
