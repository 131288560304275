//Proxima Nova

@font-face {
  font-weight: 300;
  font-family: 'ProximaNova';
  font-style: normal;
  src: url('../fonts/ProximaNova/proximanova-light.ttf') format('truetype');
}

@font-face {
  font-weight: 400;
  font-family: 'ProximaNova';
  font-style: normal;
  src: url('../fonts/ProximaNova/proximanova-regular.ttf') format('truetype');
}

@font-face {
  font-weight: 600;
  font-family: 'ProximaNova';
  font-style: normal;
  src: url('../fonts/ProximaNova/proximanova-semibold.ttf') format('truetype');
}

@font-face {
  font-weight: 700;
  font-family: 'ProximaNova';
  font-style: normal;
  src: url('../fonts/ProximaNova/proximanova-bold.ttf') format('truetype');
}

// IBMPlexSans

@font-face {
  font-weight: 300;
  font-family: 'IBMPlexSans';
  font-style: normal;
  src: url('../fonts/IBMPlexSans/ibmplexsans-light.ttf') format('truetype');
}

@font-face {
  font-weight: 400;
  font-family: 'IBMPlexSans';
  font-style: normal;
  src: url('../fonts/IBMPlexSans/ibmplexsans-regular.ttf') format('truetype');
}

@font-face {
  font-weight: 600;
  font-family: 'IBMPlexSans';
  font-style: normal;
  src: url('../fonts/IBMPlexSans/ibmplexsans-semibold.ttf') format('truetype');
}

@font-face {
  font-weight: 700;
  font-family: 'IBMPlexSans';
  font-style: normal;
  src: url('../fonts/IBMPlexSans/ibmplexsans-bold.ttf') format('truetype');
}

//Inter

@font-face {
  font-weight: 300;
  font-family: 'Inter';
  font-style: normal;
  src: url('../fonts/Inter/inter-light.ttf') format('truetype');
}

@font-face {
  font-weight: 400;
  font-family: 'Inter';
  font-style: normal;
  src: url('../fonts/Inter/inter-regular.ttf') format('truetype');
}

@font-face {
  font-weight: 600;
  font-family: 'Inter';
  font-style: normal;
  src: url('../fonts/Inter/inter-semibold.ttf') format('truetype');
}

@font-face {
  font-weight: 700;
  font-family: 'Inter';
  font-style: normal;
  src: url('../fonts/Inter/inter-bold.ttf') format('truetype');
}

@font-face {
  font-weight: 500;
  font-family: 'Inter';
  font-style: normal;
  src: url('../fonts/Inter/inter-medium.ttf') format('truetype');
}
