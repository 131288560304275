.root {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.container {
  padding: 0 60px;

  @media (max-width: 768px) {
    padding: 0 24px;
  }
}

.terms-wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: row;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 40px 0 80px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
}

.heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  margin-left: 36px;

  @media (max-width: 768px) {
    margin-left: unset;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }
}

.last-update {
  color: var(--dark-grey);

  @media (max-width: 768px) {
    display: none;
  }
}

.content {
  flex: 9;
  width: 100%;
  height: 100%;
  background-color: var(--white);

  @media (max-width: 768px) {
    flex: 1;
  }
}

.subhead {
  padding: 24px 0;

  &:first-child {
    padding-top: 0;
  }
}

.paragraph {
  padding-bottom: 24px;
}

.unordered-list {
  padding: 24px 0 24px 20px;
  list-style: disc;
}

.text-button {
  padding: 0;
  color: darkblue;
  font: inherit;
  font-size: inherit;
  background: none;
  border: none;
  cursor: pointer;
}
