.empty-block {
  align-items: center;
  justify-content: center;
  height: 674px;

  &__content {
    flex-direction: column;
    align-items: center;

    &-icon {
      margin-bottom: 12px;
    }
  }
}
