.scale {
  width: 100%;
  height: 14px;
  overflow: hidden;
  background: var(--light-grey);
  border-radius: 4px;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__inner {
    height: 100%;
    background: var(--background-primary);
    border-radius: 4px;
  }

  &__value {
    margin-left: 10px;
    color: #404040;
  }
}
