.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content {
  flex-grow: 1;
  width: 95%;
  max-width: 932px;
  height: 100%;
  margin: 0 auto;
  padding: 152px 0;

  @media (max-width: 760px) {
    width: 100%;
    padding: 0 24px 24px 24px;
  }
}

.container {
  display: flex;
  flex-direction: column;
  margin: 40px 18vw;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 210px;
  height: 210px;
  padding: 24px;
  background-color: white;
  border: none;
  border-radius: 24px;
  box-shadow: 0 8px 52px #f3f3f3;
  cursor: pointer;
  transition: background-color 0.2s ease;

  @media (max-width: 576px) {
    flex-direction: row;
    align-items: center;
    justify-content: start;
    width: 100%;
    height: 68px;
    margin-bottom: 16px;
    padding: 14px 16px;
  }

  &__title {
    @media (max-width: 576px) {
      font-weight: bold;
      font-size: 16px;
      font-style: normal;
      line-height: 20px;
    }
  }

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  &:hover {
    background-color: var(--lighter-grey);
    transition: background-color 0.2s ease;
  }

  &:not(:first-child) {
    margin-left: 32px;

    @media (max-width: 576px) {
      margin-left: 0;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background-color: var(--light-grey);
    border-radius: 16px;

    @media (max-width: 576px) {
      width: 40px;
      height: 40px;
      margin-right: 12px;
      border-radius: 12px;
    }

    svg g {
      fill: var(--background-primary);
    }

    svg path {
      fill: var(--background-primary);
    }
  }
}

.back-button {
  display: flex;
  align-items: center;
  margin-bottom: 92px;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    background-color: var(--light-grey);
    border-radius: 16px;
    transform: rotate(180deg);
    cursor: pointer;

    svg path {
      transition: 0.2s;
      fill: var(--secondary-black);
    }

    &:hover {
      svg path {
        fill: var(--black);
      }
    }
  }
}

.title {
  @media (max-width: 576px) {
    font-size: 24px;
    line-height: 32px;
  }
}

.subtitle {
  margin-top: 10px;
  margin-bottom: 60px;
  color: #b4b4b4;

  @media (max-width: 576px) {
    margin-bottom: 32px;
  }
}

.cards-container {
  display: flex;

  @media (max-width: 576px) {
    flex-direction: column;
  }
}

.force-wrap {
  width: 50%;
  text-align: start;
}

.document {
  position: relative;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: start;
  width: 100%;
  max-width: 354px;
  padding: 22px 26px;
  border-radius: 24px;
  box-shadow: 0 8px 52px #f3f3f3;

  // &:nth-child(odd) {
  //   margin-right: 24px;
  // }

  @media (max-width: 968px) {
    width: auto;
    max-width: 324px;
    // margin-top: 32px;
    // margin-left: 60px;
  }

  // @media (max-width: 768px) {
  //   margin-top: 32px;
  //   margin-left: 24px;
  // }

  @media (max-width: 576px) {
    width: auto;
    max-width: 100%;
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 56px;
    min-height: 56px;
    margin-right: 16px;
    background: #f9f9f9;
    border-radius: 50%;

    @media (max-width: 768px) {
      min-width: 56px;
      min-height: 56px;
    }
  }

  &__container {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    max-width: 800px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  &__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__info-subtitle {
    color: var(--dark-grey);

    @media (max-width: 970px) {
      white-space: pre;
    }
  }

  &__delete {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
      transition: 0.5s;
    }
  }

  &__info-title {
    min-width: 225px;
    margin-right: 5px;
    margin-bottom: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    // white-space: nowrap;
    @media (max-width: 970px) {
      font-size: 16px;
      // white-space: pre;
    }
  }
}

.text-button {
  padding: 0;
  color: var(--background-primary);
  font: inherit;
  font-size: inherit;
  background: none;
  border: none;
  cursor: pointer;
}

.more-than-five {
  color: var(--red);
  font-weight: 500;
}
