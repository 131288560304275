.expert-profile {
  padding: 32px 60px 80px 60px;

  @media (max-width: 1100px) {
    padding-right: 60px;
  }

  @media (max-width: 860px) {
    padding-right: 24px;
    padding-left: 24px;
  }

  @media (max-width: 760px) {
    flex-direction: column;
    padding: 24px;
  }
}

.expert-profile-tabs {
  min-width: 340px;
  margin-right: 28px;
  padding: 8px 32px;
  background: var(--white);
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  &__tab {
    width: 100%;
    padding: 24px 0;
    color: var(--dark-grey);
    font-weight: bold;
    font-size: 20px;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    line-height: 24px;
    letter-spacing: -0.01em;
    text-align: left;
    background: none;
    border: none;
    border-bottom: 2px solid var(--light-grey);
    appearance: none;

    &--active {
      color: var(--black);
    }

    &:last-child {
      border-bottom: none;
    }

    &:focus,
    &:active {
      outline: none;
    }
  }

  @media (max-width: 1100px) {
    width: 260px;
    min-width: 260px;
    margin-right: 20px;
    padding: 8px 26px;
  }

  @media (max-width: 760px) {
    display: none;
  }
}

.tab-content {
  flex-grow: 1;
  width: 100%;
  padding: 48px;
  background: var(--white);
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  &_no-background {
    padding: unset;
    box-shadow: unset;
  }

  @media (max-width: 760px) {
    flex-grow: 1;
    height: 100%;
    padding: 0;
    background: var(--white);
    border-radius: 16px;
    box-shadow: unset;
  }
}

.user-credentials-container {
  &__section {
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 2px solid var(--light-grey);

    &:last-child {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: none;
    }
  }

  @media (max-width: 760px) {
    &__section {
      margin-bottom: 36px;
      padding-bottom: unset;
      border-bottom: unset;
    }
  }
}

.user-subscription {
  &-tab {
    &__title {
      margin-bottom: 28px;
    }
  }

  @media (max-width: 760px) {
    &-container {
      padding: 20px 24px;
      background: #fff;
      border-radius: 16px;
      box-shadow: 0 8px 52px #f3f3f3;
    }

    &-tab {
      &__title {
        @media (max-width: 760px) {
          margin-bottom: 24px;
          font-size: 24px;
          line-height: 32px;
        }
      }
    }
  }
}

.user-recommendations-tab {
  &__waiting {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 310px;
  }
}

.subscription {
  &__name,
  &__amount {
    margin-bottom: 12px;
  }

  &__next-billing-date {
    color: var(--dark-grey);

    &_expired {
      color: var(--red);
    }
  }

  &__actions {
    flex-wrap: wrap;
    margin-top: 32px;
    padding-top: 32px;
    border-top: 2px solid var(--light-grey);
  }

  &__period {
    color: var(--dark-grey);
    text-align: end;
  }

  &__button {
    width: 200px;

    &:nth-child(1) {
      margin-right: 17px;
    }

    @media (max-width: 890px) {
      &:nth-child(1) {
        margin-right: unset;
        margin-bottom: 20px;
      }

      width: 100%;
    }

    @media (max-width: 760px) {
      &:nth-child(1) {
        margin-bottom: unset;
      }
    }

    &_renew {
      width: 248px;

      @media (max-width: 540px) {
        width: 100%;
      }
    }
  }

  &_no-button {
    margin-bottom: unset;
    padding-bottom: unset;
    border-bottom: none;
  }

  @media (max-width: 760px) {
    flex-direction: column;
    margin-bottom: 24px;
    padding-bottom: unset;
    border-bottom: unset;

    &__button {
      &:nth-child(1) {
        width: 100%;
        margin-right: unset;
      }

      &:nth-child(2) {
        display: none;
      }
    }
  }
}
