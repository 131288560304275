.menu {
  position: absolute;
  top: 62px;
  right: 24px;
  z-index: 2;
  max-width: 167px;
  padding: 0 20px;
  background: var(--white);
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 0 10px #f1f1f1;

  &__container {
    display: flex;
    flex-direction: column;
  }

  &__link {
    padding-top: 20px;
    padding-bottom: 18px;
    background: transparent;
    border: none;
    border-bottom: 1px solid var(--light-grey);
    outline: none;
  }

  &__link_delete {
    padding-top: 20px;
    padding-bottom: 18px;
    color: var(--red);
    background: transparent;
    border: none;
    outline: none;
  }
}
