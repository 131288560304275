.modal {
  &__title {
    margin-bottom: 20px;
    text-align: center;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media (max-width: 600px) {
      align-items: start;
    }
  }

  &__subtitle {
    margin-bottom: 40px;
    color: var(--dark-grey);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &__button-box {
    display: flex;

    :first-child {
      margin-right: 16px;
    }
  }
}
