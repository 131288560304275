.input {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px;
  color: var(--secondary-black);
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.01em;
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
  border-radius: 16px;
  outline: none;

  &_error {
    color: var(--red);
    border: 1px solid var(--red);
  }

  &_warning {
    border: 1px solid var(--yellow);
  }

  &_disabled {
    color: var(--light-grey);
  }

  &:focus {
    border: 1px solid var(--grey);
  }

  &::placeholder {
    color: var(--dark-grey);
  }

  @media (max-width: 640px) {
    padding: 13px;
    font-size: 14px;
    line-height: 18px;
    border-radius: 14px;
  }
}
