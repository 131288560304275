.button {
  width: 52px;
  height: 52px;
  margin: 0;
  border: 0;
  border-radius: 16px;
  cursor: pointer;

  @media (max-width: 760px) {
    width: 24px;
    height: 24px;
    border-radius: 8px;
  }

  &-primary {
    color: var(--white);
    background: var(--background-secondary);

    &:hover {
      background: var(--background-secondary-dark);
    }

    &:focus {
      background: var(--background-secondary-dark);
    }

    &:disabled {
      background: var(--background-secondary-light);
    }
  }

  &-secondary {
    color: var(--secondary-black);
    background: var(--light-grey);

    &:hover {
      background: var(--grey);
    }

    &:focus {
      background: var(--grey);
    }

    &:disabled {
      background: var(--light-grey);
    }
  }
}
