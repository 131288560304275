$block: '.forgot-password-new';

#{$block} {
  display: flex;
  flex-direction: column;
  justify-content: center;

  &__header {
    margin-top: 16px;
    margin-bottom: 40px;
  }

  &__subtitle {
    color: var(--dark-grey);
  }

  &__link {
    margin-left: 6px;
    text-decoration: none;
    outline: none;
    transition: color 0.2s;

    &:hover {
      color: var(--dark-grey);
    }
  }

  &__field {
    &:first-of-type {
      margin-top: 40px;

      @media (max-width: 640px) {
        margin-top: 45px;
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 24px;

      @media (max-width: 640px) {
        margin-bottom: 28px;
      }
    }

    &:last-of-type {
      margin-bottom: 36px;
    }
  }

  &__repeat-code {
    color: var(--dark-grey);
  }
}
