.brand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.header {
  z-index: 5;
  min-height: 100px;
  padding: 24px 60px;
  background-color: var(--white);

  h1 {
    margin: 0;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -0.01em;
  }

  .logo {
    margin-right: 20px;

    svg {
      display: flex;
    }
  }

  @media (max-width: 930px) {
    &-content {
      button {
        margin-right: 15px !important;
      }
    }
  }

  @media (max-width: 760px) {
    min-height: 64px;
    padding: 16px 24px;

    &-content {
      button {
        display: none;
      }
    }

    h1 {
      font-size: 20px;
      line-height: 24px;
    }

    .logo {
      svg {
        width: auto;
        height: 24px;
      }
    }
  }
}

.container {
  display: flex;
  column-gap: 24px;

  @media (max-width: 760px) {
    column-gap: unset;
  }
}

.icons {
  display: flex;
  height: 32px;
  column-gap: 24px;

  @media (max-width: 760px) {
    display: flex;
    height: 24px;
    column-gap: 16px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
