.dropdown {
  &__icon {
    display: flex;
    cursor: pointer;
    transition: all 0.2s;

    & svg {
      width: 12px;
      height: 7px;

      path {
        transition: all 0.2s;
      }

      @media (max-width: 460px) {
        width: 11px;
        height: 6px;
      }
    }

    &_active {
      transform: rotate(180deg);
    }

    &_error,
    &_warning {
      svg path {
        fill: var(--red);
      }
    }

    &:hover {
      svg path {
        fill: var(--secondary-black);
      }
    }
  }
}
