.promocode {
  &__form {
    margin-top: 38px;

    @media (max-width: 540px) {
      margin-top: 17px;
    }
  }

  &__button {
    margin-top: 36px;
  }
}
