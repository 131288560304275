.input {
  margin-bottom: 32px;
}

.form-patient {
  &__field {
    width: 100%;
    max-width: 362px;

    @media (max-width: 968px) {
      max-width: 100%;
      margin-bottom: 24px;
    }
  }

  &__header {
    @media (max-width: 968px) {
      margin-bottom: 29px;
    }
  }

  &__field-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 32px;

    @media (max-width: 968px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  &__button {
    float: right;
    max-width: 200px;

    @media (max-width: 968px) {
      float: unset;
      max-width: 100%;
    }
  }
}
