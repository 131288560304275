.text-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: auto;
  min-height: 52px;
  padding: 15px 60px 15px 16px;
  color: #404040;
  font-weight: 400;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.01em;
  background-color: var(--light-grey);
  border: 1px solid var(--light-grey);
  -webkit-border-radius: 16px;
  -moz-border-radius: 16px;
  border-radius: 16px;
  outline: none;
  resize: none;

  @media (max-width: 760px) {
    padding-right: 0;
  }

  &__wrapper {
    width: 100%;
    min-height: 52px;
    padding: 15px 60px 15px 16px;
    word-break: break-word;
    background-color: var(--light-grey);
    border: 1px solid var(--light-grey);
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
  }

  &_error {
    color: var(--red);
    border: 1px solid var(--red);
  }

  &__big {
    min-height: 152px;
    background-color: var(--light-grey);
  }

  &_warning {
    border: 1px solid var(--yellow);
  }

  &:focus {
    border: 1px solid var(--grey);
  }

  &::placeholder {
    color: var(--dark-grey);
  }

  &:disabled {
    display: block;
    background: var(--white);
    border: unset;
    border-radius: unset;
  }

  @media (max-width: 640px) {
    padding: 13px;
    font-size: 14px;
    line-height: 18px;
    border-radius: 14px;
  }
}

.span {
  display: block;
  line-height: 20px;
}
