.dashboard {
  &__title {
    width: 100%;
    max-width: 900px;
    margin-left: 60px;
    overflow: hidden;
    font-size: 32px;
    line-height: 42px;
    text-overflow: ellipsis;

    @media (max-width: 768px) {
      margin-left: 0;
      padding-left: 24px;
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__link {
    display: none;

    @media (max-width: 750px) {
      display: block;
      float: right;
      margin-top: 50px;
    }
  }

  &__button-box {
    max-width: 204px;

    @media (max-width: 750px) {
      display: none;
    }
  }

  &__head {
    margin-top: 132px;
    margin-bottom: 52px;
  }
}

.subscriptions {
  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    padding-left: 60px;

    @media (max-width: 670px) {
      margin: 0 auto;
      padding-right: 24px;
      padding-left: 24px;
    }
  }

  &__link {
    display: none;

    @media (max-width: 750px) {
      display: block;
      float: right;
      margin-top: 50px;
      margin-right: 24px;
    }
  }

  &__button {
    max-width: 204px;

    @media (max-width: 750px) {
      display: none;

      &__link {
        display: none;
      }
    }
  }

  &__head {
    margin-top: 132px;
    margin-bottom: 52px;
    padding-right: 60px;
    padding-left: 60px;

    @media (max-width: 750px) {
      margin-top: 86px;
      margin-bottom: 24px;
      padding-right: 24px;
      padding-left: 24px;
    }
  }
}

.search {
  &__title {
    margin: 0 0 0 60px;

    @media (max-width: 768px) {
      display: none;
    }
  }
}

.dashboard__header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title_container {
  display: flex;
  align-items: center;
  column-gap: 4px;

  @media (max-width: 768px) {
    padding-right: 25px;
  }
}

.subtitle {
  display: flex;
  flex-direction: row;
  column-gap: 24px;

  @media (max-width: 768px) {
    margin-top: 12px;
    padding-left: 24px;
  }
}

.limit_container {
  display: flex;
  align-items: center;
  column-gap: 4px;
}
