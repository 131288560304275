.anamnesis {
  display: flex;
  flex-direction: column;

  &__heading {
    color: #404040;
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.01em;
  }

  &__subheading {
    margin-top: 40px;
    margin-bottom: 32px;
    color: #404040;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;

    @media (max-width: 640px) {
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__flex-block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
    gap: 32px;
    margin-bottom: 32px;
  }

  &__block {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 32px;
    align-items: flex-start;
  }

  &-placeholder {
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 146px;
    padding-bottom: 16px;
    border-bottom: 2px solid #f7f7f7;

    @media (max-width: 640px) {
      gap: 4px;
      padding-bottom: 12px;
    }

    &__label {
      color: #8e8e8e;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: -0.01em;
    }

    &__body {
      overflow: hidden;
      color: #404040;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: -0.01em;
      word-wrap: anywhere;

      @media (max-width: 640px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}

.long-placeholder {
  @media (max-width: 480px) {
    grid-column: span 2;
  }
}
