.empty-message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: calc(100vh - 254px);
  margin-right: 60px;
  margin-bottom: 48px;
  margin-left: 60px;
  background: var(--white);
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 768px) {
    height: calc(100vh - 74px);
    margin: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__text {
    max-width: 350px;
    margin-top: 28px;
    margin-bottom: 40px;
    color: var(--black);
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
}
