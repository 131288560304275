.terms {
  display: flex;
  align-items: flex-start;

  &-text {
    &--error {
      color: var(--red);
    }
  }

  &-link {
    color: var(--background-primary);
  }
}
