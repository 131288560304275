.expert-profile {
  padding: 24px;

  &__title {
    margin-bottom: 26px;
  }

  &__links {
    display: flex;
    flex-direction: column;
  }

  &__tabs-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }

  &__sign-out-btn {
    margin-top: 20px;
    cursor: pointer;

    p {
      color: var(--red);
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
    }
  }

  &__delete-btn {
    margin-top: 44px;
    cursor: pointer;

    p {
      color: var(--black);
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      transition: color 0.2s;

      &:hover {
        color: var(--dark-grey);
      }
    }
  }

  &__link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--dark-grey);

    &-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      transition: color 0.2s;

      &:hover {
        color: var(--text-primary);
      }
    }

    svg {
      width: 12px;
      height: 7px;
      transform: rotate(-90deg);
    }

    &:not(:last-child) {
      margin-bottom: 27px;
    }
  }
}
