.disclaimer {
  display: flex;
  align-items: center;
  justify-content: start;
  margin-top: 32px;

  &__exclamator {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin-right: 12px;
    background: rgba(#646efa, 0.3);
    border-radius: 50%;
  }

  &__text {
    width: 100%;
    max-width: 760px;
    color: var(--dark-grey);
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
  }
}
