.onboarding-container {
  padding-top: 40px;
  padding-left: 15%;

  @media (max-width: 576px) {
    padding: 24px;
  }

  .previous-step {
    height: 120px;

    @media (max-width: 576px) {
      height: 63px;
    }

    &__title {
      color: var(--dark-grey);
      font-family: 'Inter', sans-serif;

      @media (max-width: 576px) {
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
      }
    }

    &__btn {
      @media (max-width: 576px) {
        display: none;
      }

      svg {
        width: 15px;
        height: 15px;
      }
    }

    &__btn--mobile {
      button {
        display: none;
        background: none;
        border: none;
        appearance: none;

        svg {
          width: 12px;
          height: 12px;
          margin-right: 11px;
          transform: rotate(180deg);
        }

        @media (max-width: 576px) {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}

.onboarding-steps-navigate {
  &__step {
    width: 100%;
    max-width: 80px;
    height: 8px;
    margin-right: 8px;
    margin-bottom: 40px;
    border-radius: 4px;

    @media (max-width: 576px) {
      max-width: 40px;
      margin-bottom: 32px;
    }

    &:last-child {
      margin-right: 0;
    }

    &-complete {
      background-color: var(--background-primary);
    }

    &-incomplete {
      background-color: var(--grey);
    }
  }
}

.onboarding-form-wrapper {
  max-width: 432px;
  font-family: 'Inter', sans-serif;
}

.onboarding-step {
  &__title {
    margin-bottom: 24px;

    @media (max-width: 576px) {
      margin-bottom: 16px;
    }
  }

  &__subtitle {
    margin-bottom: 32px;
    color: var(--dark-grey);

    @media (max-width: 576px) {
      margin-bottom: 41px;
    }
  }

  &__input {
    max-width: 372px;
    margin-bottom: 24px;

    @media (max-width: 576px) {
      width: 100%;
      max-width: unset;
      margin-bottom: 23px;
    }
  }

  &__input-label {
    margin-bottom: 12px;
  }

  &__input-error {
    margin-bottom: 24px;
    color: var(--red);
    font-weight: 500;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
  }

  &__btn {
    width: 200px;
    margin-left: 0;

    @media (max-width: 576px) {
      width: 100%;
    }
  }
}

.number-masked-input {
  @media (max-width: 576px) {
    padding: 13px 12px;
  }
}

.date-picker-input {
  @media (max-width: 576px) {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.dropdown-input {
  @media (max-width: 576px) {
    min-height: 46px;
  }
}
