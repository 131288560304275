$block: '.checkbox';

#{$block} {
  display: flex;
  align-items: center;
  align-self: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background-color: var(--white);
  border: 2px solid var(--grey);
  border-radius: 6px;
  transition: all 0.3s ease;

  &:hover {
    border: 2px solid var(--background-primary-light);
  }

  &--error {
    border-color: var(--red);
  }

  &--checked {
    background-color: var(--background-primary);
    border-color: var(--background-primary);
  }

  &--disabled {
    background-color: var(--light-grey);
    border-color: var(--light-grey);
    cursor: default;
  }

  &__label {
    align-self: center;
    margin-left: 12px;

    @media (max-width: 560px) {
      font-size: 14px;
    }
  }

  &-wrapper {
    display: flex;
    cursor: pointer;
  }
}
