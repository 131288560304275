.letter {
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  padding-right: 20px;
  padding-left: 20px;
  background: transparent;
  border: none;
  border-left: 1px solid transparent;

  @media (max-width: 775px) {
    margin-bottom: 0;
  }

  &__active {
    border-left: 2px solid #646efa;
  }

  &__content {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 308px;
    padding-top: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid var(--light-grey);

    @media (max-width: 960px) {
      max-width: 100%;
    }
  }

  &__notification {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    min-width: 20px;
    height: 20px;
    color: var(--white);
    font-size: 12px;
    background: var(--red);
    border-radius: 50%;
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    min-width: 56px;
    height: 56px;
    min-height: 56px;
    margin-right: 16px;
    background: #f9f9f9;
    border-radius: 50%;
  }

  &__info {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__info-subtitle {
    color: var(--dark-grey);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
    overflow-wrap: anywhere;
  }

  &__info-title {
    color: var(--black);
    font-weight: 700;
    font-size: 16px;
    text-align: left;
    overflow-wrap: anywhere;

    @media (max-width: 860px) {
      font-size: 16px;
    }
  }

  &__info-date {
    margin-top: 4px;
    margin-left: 8px;
    color: var(--dark-grey);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  &__right {
    justify-content: end;
  }

  &__current {
    padding-left: 20px;
    border-left: 2px solid #646efa;
  }
}
