$block: '.aside';

#{$block} {
  &-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &-history {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 354px;

    &__subtitle {
      margin-top: 12px;
      color: var(--dark-grey);
    }

    &__list {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;
    }

    &__item {
      &:not(:last-of-type) {
        margin-bottom: 20px;
      }
    }

    &__empty-requests {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      min-height: 230px;
      margin-bottom: 46px;
      background: var(--white);
      border-radius: 16px;
      box-shadow: 0 8px 52px #f3f3f3;
    }

    @media (max-width: 1480px) {
      flex-basis: 100%;
      width: 100%;
    }

    @media (max-width: 760px) {
      display: none;
    }
  }

  &-show-all {
    margin-left: auto;
    color: var(--dark-grey);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    transition: 0.2s;

    p {
      color: var(--dark-grey);
    }

    &:hover {
      color: var(--black);
    }
  }

  @media (max-width: 760px) {
    &-show-all {
      order: 2;
      margin-bottom: 16px;
      margin-left: unset;
    }
  }

  &-empty {
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    height: 100%;
    max-height: 670px;
    padding: 24px 32px;
    background: var(--white);
    border-radius: 12px;
    box-shadow: 0 8px 52px #f3f3f3;

    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    &__icon {
      width: 64px;
      height: 64px;
    }

    &__title {
      margin-top: 44px;
      text-align: center;

      @media (max-width: 760px) {
        margin-top: 24px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__subtitle {
      margin-top: 20px;
      margin-bottom: 24px;
      color: var(--dark-grey);
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      text-align: center;
    }

    @media (max-width: 500px) {
      padding: 0;
      box-shadow: unset;
    }
  }
}
