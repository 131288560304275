.remove-account {
  &__text {
    padding: 16px 0 40px;
    color: var(--dark-grey);

    @media (max-width: 460px) {
      padding-bottom: 56px;
    }
  }

  &__button {
    width: 177px;

    @media (max-width: 460px) {
      width: 133px;
      min-width: unset;
    }
  }
}
