$block: '.radio-button';

#{$block} {
  display: none;

  &__label {
    display: flex;
    align-items: flex-start;
    font-weight: normal;
    font-size: 14px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: -0.01em;
    cursor: pointer;
  }

  &--error {
    border-color: var(--red);
  }

  &--checked {
    background-color: var(--background-primary);
    border-color: var(--background-primary);
  }

  &--disabled {
    background-color: var(--light-grey);
    border-color: var(--light-grey);
    cursor: default;
  }

  &-wrapper {
    display: flex;
    cursor: pointer;
  }
}

label {
  display: flex;
  // align-items: center;
}

input[type='checkbox'],
input[type='radio'] {
  width: 0;
  opacity: 0;
}

.radio {
  display: flex;
  align-items: flex-start;
}

.radio label::before {
  display: inline-block;
  min-width: 24px;
  height: 24px;
  margin-right: 0.5em;
  padding: 0.13em;
  background-color: transparent;
  background-clip: content-box;
  border: 2px solid var(--grey);
  border-radius: 100%;
  transition: all 0.2s ease;
  content: '';
}

.radio input:hover + label::before {
  border-color: var(--background-primary-light);
  cursor: pointer;
}

.radio input:checked + label::before {
  background-color: var(--background-primary);
  border-color: var(--background-primary);
}

.radio input:disabled + label {
  color: #d2d2d2;
  border-color: #d2d2d2;
}

.radio input:disabled + label::before {
  border-color: #d2d2d2;
}

.radio input:disabled:checked + label::before {
  background-color: #d2d2d2;
}
