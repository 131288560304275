$block: '.sign-in';

#{$block} {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 32px;

  &__header {
    display: flex;
    justify-content: center;
    margin-top: 32px;

    @media (max-width: 640px) {
      margin-bottom: 45px;
    }
  }

  &__subtitle {
    color: var(--dark-grey);
  }

  &__link {
    margin-left: 6px;
    font-weight: 600;
    text-decoration: none;
    outline: none;
    transition: color 0.2s;

    &:hover {
      color: var(--dark-grey);
    }

    @media (max-width: 640px) {
      margin-left: 4px;
    }
  }

  &__field {
    &:not(:last-of-type) {
      margin-bottom: 24px;

      @media (max-width: 640px) {
        margin-bottom: 28px;
      }
    }

    &:last-of-type {
      margin-bottom: 36px;
    }
  }
}
