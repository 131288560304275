.payments-diary {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 1000px) {
      flex-direction: column;
      align-items: unset;

      & button {
        width: 100% !important;
        margin-top: 24px;
      }
    }
  }

  &__download-button {
    width: 132px;
    min-width: 132px;
    height: 40px;
    min-height: 40px;
  }
}

.table {
  &__header {
    margin-top: 32px;
    margin-right: -48px;
    margin-left: -48px;

    @media (max-width: 1000px) {
      display: none;
    }
  }

  &__body {
    margin-top: 28px;
  }

  &__row {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding-bottom: 24px;

    &:not(:last-child) {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid var(--light-grey);
    }

    @media (max-width: 1000px) {
      flex-direction: column;
    }
  }

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__cell {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 24px;
    }

    @media (max-width: 1000px) {
      width: 100% !important;
    }
  }

  &__close-icon {
    display: flex;
    margin-left: 10px;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      svg path {
        transition: 0.3s;
        fill: var(--red);
      }
    }
  }

  &__button {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid var(--light-grey);
  }

  &__button_delete {
    position: absolute;
    right: 0;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    opacity: 0;
  }
}

.table__row:hover .table__button_delete {
  opacity: 1;
  transition: 0.7s;
}

.payment-item {
  display: flex;
  // flex-direction: column;
  flex-wrap: wrap;
  padding-top: 22px;
  padding-bottom: 22px;

  &:first-child {
    border-top: 2px solid var(--light-grey);
  }

  &:not(:last-child) {
    border-bottom: 2px solid var(--light-grey);
  }

  &__field_last {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  &__field {
    display: flex;
    flex-direction: column;
    width: 100%;

    &:nth-child(2),
    &:nth-child(3) {
      width: 48%;
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.table__button_delete_mobile {
  display: flex;
  align-items: center;
  margin-right: 16px;
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.empty_tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 457px;
  margin-top: 220px;
  margin-bottom: 220px;

  @media (max-width: 768px) {
    margin-top: 140px;
    margin-bottom: 140px;
  }
}

.empty_header {
  margin-top: 10px;
  margin-bottom: 24px;
  color: var(--black);
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
}

.empty_content {
  margin-bottom: 40px;
  color: var(--dark-grey);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
