.subscriptions {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 34px;
  row-gap: 24px;

  @media (min-width: 970px) {
    flex-direction: row;
    margin-top: 34px;
  }
}
