.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 52px;
  min-height: 52px;
  background-color: var(--light-grey);
  border-radius: 16px;
  transition: background-color 0.3s ease;

  &_active {
    background-color: var(--grey);
  }

  &:hover {
    background-color: var(--grey);

    svg path {
      transition: fill 0.3s ease;
      fill: var(--blue);
    }
  }

  &_disabled {
    background-color: var(--light-grey);
    cursor: default;

    &:hover {
      background-color: var(--light-grey);

      svg path {
        fill: var(--dark-grey);
      }
    }
  }
}
