.switch {
  &__wrapper {
    display: flex;
    align-items: center;
  }

  // &__label {
  //   margin-left: 14px;
  // }
}
