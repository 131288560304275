.card {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    max-width: 297px;
    height: 100%;
    height: 154px;
    padding: 28px 23px;
    background: #fff;
    border-radius: 16px;

    @media (max-width: 768px) {
      min-width: 297px;
    }
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__icon {
    width: 48px;
    height: 48px;
    margin-right: 6px;
  }

  &__text {
    max-width: 195px;
    color: var(--secondary-black);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.01em;
  }
}
