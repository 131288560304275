.heading {
  margin-bottom: 20px;
  color: var(--black);
  font-weight: 800;
  font-size: 32px;
  line-height: 42px;
  text-align: center;

  @media (max-width: 720px) {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-align: left;
  }
}

.content {
  margin-bottom: 36px;
  color: var(--dark-grey);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;

  @media (max-width: 720px) {
    text-align: left;
  }
}
