.main-menu {
  position: absolute;
  top: 40px;
  right: 0;
  z-index: 5;
  min-width: 146px;
  padding: 0 20px;
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0 6px 32px rgba(23, 20, 52, 0.05);

  .menu-item {
    display: block;
    padding: 18px 0;
    color: var(--black);
    font-weight: normal;
    font-size: 14px;
    font-family: 'IBMPlexSans', sans-serif;
    font-style: normal;
    line-height: 20px;
    letter-spacing: -0.016em;
    text-decoration: none;
    border-top: 1px solid var(--light-grey);

    &:last-child {
      color: var(--red);
    }
  }
}
