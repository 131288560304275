.loader {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;

  &-primary {
    border-top: 3px solid var(--spinner-primary-border);
    border-right: 3px solid var(--spinner-primary-border);
    border-bottom: 3px solid var(--spinner-primary-border);
    border-left: 3px solid var(--spinner-primary-border-sector);
  }

  &-message {
    border-top: 3px solid var(--spinner-primary-border);
    border-right: 3px solid var(--spinner-primary-border);
    border-bottom: 3px solid var(--spinner-primary-border);
    border-left: 3px solid var(--spinner-primary-border-sector);
  }

  &-secondary {
    border-top: 3px solid var(--spinner-secondary-border);
    border-right: 3px solid var(--spinner-secondary-border);
    border-bottom: 3px solid var(--spinner-secondary-border);
    border-left: 3px solid var(--spinner-secondary-border-sector);
  }
}

@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
