.drop-zone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 100%;
  max-height: 632px;
  margin: 16px;
  border: 2px dashed #dbdbdb;

  @media (max-width: 960px) {
    max-height: calc(100vh - 110px);
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__text {
    z-index: -1;
    color: var(--dark-grey);
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
  }
}
