.files-modal {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 24px;
  overflow-x: hidden;

  &__empty {
    height: 100%;
  }
}

.empty-container {
  width: 100%;
  height: 100%;
}
