.experts {
  &__listing {
    display: grid;
    grid-template-columns: 1fr 1fr;
    max-width: 100%;
    column-gap: 16px;
    row-gap: 16px;

    @media (max-width: 760px) {
      grid-template-columns: 1fr;
      row-gap: 32px;
    }
  }
}

.waiting {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  padding: 60px 80px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 840px) {
    padding: unset;
    box-shadow: unset;
  }
}
