:root {
  --black: #262626;
  --primary: #2a2a2a;
  --secondary-black: #404040;
  --grey: #dbdbdb;
  --light-grey: #f6f6f6;
  --lighter-grey: #f9f9f9;
  --dark-grey: #8e8e8e;
  --text-primary: #4a4a4a;
  --text-secondary: #9b9b9b;
  --white: #fff;
  --red: #fb7b7b;
  --secondary-red: #f9a0a0;
  --dark-red: #e46c6c;
  --light-red: #ffc2c2;
  --yellow: #f3b63e;
  --blue: #646efa;
  --dark-blue: #4f57c6;
  --light-blue: #a1a7ff;
  --secondary-yellow: #f9e72d;
  --background-primary: #646efa;
  --background-primary-light: #a1a7ff;
  --background-primary-lighter: #d9e5ff;
  --background-secondary: #59cda4;
  --background-secondary-dark: #35b185;
  --background-secondary-light: hsl(159, 50%, 79%);
  --background-secondary-lighter: #eefaf6;
  --background-black: #3f3f3f;
  --background-yellow: rgba(243, 182, 62, 0.15);
  --spinner-primary-border: rgba(255, 255, 255, 0.4);
  --spinner-primary-border-sector: var(--white);
  --spinner-secondary-border: rgba(180, 180, 180, 0.58);
  --spinner-secondary-border-sector: var(--dark-grey);
  --svg-grey: #d9d9d9;
}
