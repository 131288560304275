.datepicker {
  box-sizing: border-box;

  &__box {
    margin-right: 24px;
    padding: 1px;

    @media (max-width: 968px) {
      margin-right: 0;
      box-shadow: 0 6px 32px rgba(23, 20, 52, 0.05);
    }
  }

  &-wrapper {
    width: 336px;
    min-width: 320px;
    margin-top: 67px;
    background-color: var(--white);
    border-radius: 16px;
    box-shadow: 0 8px 52px #f3f3f3;

    @media (max-width: 968px) {
      width: auto;
      max-width: 345px;
      margin-top: 0;
    }

    @media (max-width: 576px) {
      border-radius: 14px;
    }
  }

  &__caption {
    display: table-caption;
    margin-bottom: 26px;
    padding: 0 0.5em;
    text-align: center;

    & > div {
      display: none;
      color: var(--black);
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      text-transform: capitalize;
    }
  }

  &__container {
    display: inline-block;
    width: 336px;
    padding: 16px 20px;

    @media (max-width: 576px) {
      border-radius: 14px;
    }

    @media (max-width: 385px) {
      width: 100%;
    }
  }

  &__wrapper {
    position: relative;
    flex-direction: row;
    outline: none;
    user-select: none;
  }

  &__months {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    &:focus {
      outline: none;
    }
  }

  &__month {
    display: table;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    user-select: none;

    &:focus {
      outline: none;
    }
  }

  &__day {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    padding: 0.3em;
    color: var(--primary);
    font-weight: normal;
    font-size: 14px;
    font-style: normal;
    line-height: 18px;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: var(--background-secondary-lighter);
    }

    &:focus {
      outline: none;
    }

    &_outside {
      color: var(--grey);
      cursor: default;
    }

    @media (max-width: 385px) {
      width: 28px;
      height: 28px;
    }
  }

  &__weekday {
    display: table-cell;
    padding: 0.3em;
    color: var(--dark-grey);
    font-weight: 500;
    font-size: 12px;
    font-style: normal;
    line-height: 16px;
    text-align: center;

    & abbr[title] {
      text-transform: capitalize;
      text-decoration: none;
      border-bottom: none;
    }
  }

  &__weekdays {
    display: flex;
    margin-bottom: 13px;
  }

  &-weekdays {
    &__row {
      display: flex;
      flex-grow: 1;
      justify-content: space-between;
    }
  }

  &__navbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-navbar {
    &__date-select {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 71%;

      @media (max-width: 385px) {
        max-width: 77%;
      }
    }
  }

  &__nav-button {
    position: absolute;
    top: 1em;
    right: 1.5em;
    left: auto;
    display: inline-block;
    width: 1.25em;
    height: 1.25em;
    margin-top: 2px;
    margin-right: 1.5em;
    color: #8b9898;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 50%;
    cursor: pointer;

    &_prev {
      float: left;
    }

    &_next {
      float: right;
    }

    &-interaction {
      &_disabled {
        background-color: red;
      }
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__week {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:focus {
      outline: none;
    }

    &-number {
      display: table-cell;
      min-width: 1em;
      padding: 0.5em;
      color: #8b9898;
      font-size: 0.75em;
      text-align: right;
      vertical-align: middle;
      border-right: 1px solid #eaecec;
      cursor: pointer;
    }
  }

  &__interaction {
    &_disabled {
      cursor: default;
    }
  }

  &__footer {
    padding-top: 0.5em;
  }

  /* Default modifiers */

  &__today {
    &-button {
      color: #4a90e2;
      font-size: 0.875em;
      background-color: transparent;
      background-image: none;
      border: none;
      box-shadow: none;
      cursor: pointer;
    }
  }

  &_disabled {
    color: #dce0e0;
    cursor: default;

    &:hover {
      background-color: unset;
    }
  }

  &_selected {
    color: #fff;
    background-color: var(--background-primary);
    cursor: default;

    &:hover {
      background-color: var(--background-primary);
    }
  }

  &__icon {
    display: flex;
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 2s;

    svg path {
      fill: var(--dark-grey);
    }

    &_right {
      svg {
        transform: rotate(-90deg);
      }

      &:hover {
        svg path {
          fill: var(--secondary-black);
        }
      }
    }

    &_left {
      svg {
        transform: rotate(90deg);
      }

      &:hover {
        svg path {
          fill: var(--secondary-black);
        }
      }
    }
  }

  &__actions {
    width: 100%;
    padding: 4px 16px 16px;

    &-btn {
      width: 148px;
      min-width: unset;
      height: 42px;
    }
  }

  &__date {
    text-transform: capitalize;

    @media (min-width: 768px) {
      font-weight: 800;
      font-size: 32px;
      line-height: 42px;
    }
  }
}

.filter {
  margin-right: 24px;
  margin-left: 24px; // потом убрать
  padding-top: 24px;
  padding-bottom: 24px;
  border-top: 1px solid var(--grey);

  &__container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 20px;
  }

  &__container:last-child {
    margin-bottom: 0;
  }

  &__title {
    display: flex;
    align-items: center;
  }
}

.dot {
  &__call {
    min-width: 12px;
    min-height: 12px;
    margin-right: 12px;
    background: #35b185;
    border-radius: 50%;
  }

  &__video {
    min-width: 12px;
    min-height: 12px;
    margin-right: 12px;
    background: #f3b63e;
    border-radius: 50%;
  }

  &__meeting {
    min-width: 12px;
    min-height: 12px;
    margin-right: 12px;
    background: #646efa;
    border-radius: 50%;
  }
}

.toolbar {
  width: 116px;
  height: 40px;
  margin-right: 20px;
  border: none;
  border-radius: 12px;

  &__container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  &__navigation {
    position: absolute;
    display: flex;
    align-items: center;
    margin-right: 24px;
  }

  &__arrows {
    display: flex;
    align-items: center;
    margin-left: 16px;
  }

  &__arrow {
    width: 30px;
    height: 30px;
    margin-right: 8px;
    background: transparent;
    border: none;
    cursor: pointer;
  }

  &__active {
    color: white;
    background-color: #646efa;
  }
}

.button {
  display: flex;
  align-items: center;

  &__text {
    margin-left: 8px;
  }
}
