.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 210px 0;
}

.empty__icon {
  max-width: 100px;
  margin-bottom: 32px;
}

.heading {
  margin-bottom: 24px;
  color: var(--black);
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.content {
  max-width: 360px;
  margin-bottom: 40px;
  color: var(--dark-grey);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
