.container {
  width: 100%;
  height: 100%;
  padding: 32px 32px 12px 32px;
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 760px) {
    padding: 0 0 16px 0;
    box-shadow: unset;
  }
}

.add__biomarker {
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  padding: 0;
  color: var(--background-secondary-dark);
  background: none;
  border: none;
  cursor: pointer;
  appearance: none;
}

.add__biomarker__icon {
  padding: 0 4px;
}

.add__biomarker__text {
  font-weight: 500;
  font-size: 14px;
  font-style: normal;
  line-height: 17px;
}

.biomarkers__container {
  width: 100%;
  max-width: 100%;
}

.biomarkers__header {
  margin-bottom: 24px;
  color: var(--dark-grey);
}

.header__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input__analysis_name {
  max-width: 270px;
  margin-bottom: 28px;
}

.separator {
  margin-bottom: 28px;
  border: 1px solid #f7f7f7;
}
