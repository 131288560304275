.modal__form {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.modal__inner {
  width: 100%;
  margin-top: 48px;
  margin-bottom: 48px;
}

.modal__button {
  max-width: 200px;
}
