.dashboard {
  display: block;
  // max-width: 1440px;
  margin: 0 auto;

  &__header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 22px;

    @media (max-width: 968px) {
      flex-direction: column;
      margin-bottom: 32px;
    }
  }
}

.root {
  display: flex;
  flex-direction: column;
  // max-width: 1440px;
  min-height: 100%;
  margin: 0 auto;
}

.content {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 30px 0 0;
  background-color: var(--white);
}
