.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-height: 52px;
  margin-top: 26px;
  margin-bottom: 22px;
  padding-right: 60px;
  padding-left: 60px;

  @media (max-width: 768px) {
    padding-right: 24px;
    padding-left: 24px;

    h2 {
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }
  }
}

.title {
  display: flex;
  align-items: center;
}

.button__back {
  display: flex;
  align-items: center;
  margin-top: 20px;
  margin-left: 24px;
  background: transparent;
  border: none;
  outline: none;

  @media (max-width: 768px) {
    margin-top: 0;
    margin-left: 0;
  }
}

.anchor__back {
  margin-bottom: 0;
  margin-left: 11px;
  color: var(--dark-grey);
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
}

.container__title {
  display: flex;
}

.arrow__back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  margin-right: 36px;
  background-color: var(--light-grey);
  border: none;
  border-radius: 16px;
  transform: rotate(180deg);
  cursor: pointer;

  svg {
    width: 15px;
    height: 12px;

    path {
      transition: 0.2s;
      fill: var(--secondary-black);
    }
  }

  &:hover {
    svg path {
      fill: var(--black);
    }
  }

  @media (max-width: 640px) {
    svg {
      width: 12.5px;
      height: 10px;
    }
  }
}
