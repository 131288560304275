.table-header {
  display: flex;
  align-items: center;
  max-width: 100%;
  height: 64px;
  padding: 0 60px;
  background: var(--lighter-grey);

  &__cell {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-right: 16px;
    }

    & > p {
      color: var(--dark-grey);
      letter-spacing: -0.01em;
    }
  }

  &__icon {
    display: flex;
    margin-left: 10px;
    background: none;
    border: none;
    cursor: pointer;
  }
}
