.root {
  // max-width: 1440px;
  //min-height: 100%;
  // height: 100vh;
  // margin: 0 auto;
  // display: flex;
  // flex-direction: column;
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.content {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 30px 0 0;
  background-color: var(--white);
}
