.patient-info-form {
  flex-wrap: wrap;
  margin-bottom: 16px;

  &__input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 24px;

    label {
      display: block;
      margin-bottom: 12px;
      font-family: 'Inter', sans-serif;
      letter-spacing: -0.01em;
    }

    @media (max-width: 1100px) {
      flex-basis: 100%;
    }
  }

  &__label {
    color: #000;
    font-weight: 400;
    font-size: 16px;
    font-family: 'Inter', sans-serif;
    line-height: 20px;
  }

  &__input-photo {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;

    @media (max-width: 765px) {
      border-radius: 50%;
    }
  }

  &__container-input {
    position: relative;
    height: 100%;
  }

  &__buttons {
    flex-wrap: wrap;

    @media (max-width: 1100px) {
      width: 100%;

      button {
        width: 100% !important;

        &:last-child {
          margin-top: 20px !important;
        }
      }
    }

    @media (max-width: 765px) {
      button {
        &:last-child {
          margin-top: unset !important;
        }
      }
    }
  }

  &__title {
    display: block;
    margin-bottom: 16px;

    @media (max-width: 765px) {
      margin-bottom: 28px;
    }
  }

  &__subtitle {
    margin: 0 0 40px;
    color: var(--dark-grey);
  }

  &__remove-button {
    @media (max-width: 765px) {
      display: none;
    }
  }

  &__input-error {
    margin-bottom: 24px;
    color: var(--red);
  }

  @media (max-width: 760px) {
    align-content: flex-start;
  }

  &__styled-hr {
    margin: 15px 0 32px 0;
    border: 1px solid #f7f7f7;
  }

  &__biography {
    margin-bottom: 40px;
    padding: 20px 60px 60px 20px;
    border-radius: 16px;

    @media (max-width: 760px) {
      margin-bottom: 24px;
      padding: 20px;
    }

    &__information {
      color: #404040;
      font-weight: normal;
      font-size: 16px;
      font-style: normal;
      line-height: 24px;
      letter-spacing: -0.01em;
    }
  }
}

.mobileButton {
  width: 152px;
  min-width: 152px;
  height: 40px;
  padding: 10px 35px;

  @media (max-width: 760px) {
    display: none;
  }
}

.load-picture {
  margin-bottom: 32px;

  @media (max-width: 765px) {
    justify-content: center;
    margin-bottom: 20px;
  }

  &__img {
    width: 56px;
    max-width: 100%;
    height: 56px;
    max-height: 100%;
    background-position: top center;
    background-size: cover;
    border-radius: 50%;

    @media (max-width: 765px) {
      width: 120px;
      max-width: 100%;
      height: 120px;
      max-height: 100%;
    }
  }

  &__svg {
    width: 24px;
    height: 24px;

    @media (max-width: 765px) {
      width: 55px;
      height: 55px;
    }
  }

  &__load-title {
    color: var(--dark-grey);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;

    @media (max-width: 765px) {
      width: 175px;
      font-size: 12px;
      line-height: 16px;
      text-align: center;
    }
  }

  &__user-icon {
    min-width: 56px;
    height: 56px;
    border-radius: 16px;
    cursor: pointer;

    @media (max-width: 765px) {
      width: 120px;
      height: 120px;
      border-radius: 83px;
    }
  }

  &__user-container {
    gap: 20px;
    margin-right: 8px;

    @media (max-width: 765px) {
      flex-direction: column;
    }
  }

  &__title {
    display: none;
    color: #262626;
    font-size: 14px;

    @media (max-width: 765px) {
      display: block;
    }
  }
}

.textarea {
  background-color: #f7f7f7;
}

.buttons {
  display: flex;
  gap: 12px;
}

.avatar-button {
  width: 56px;
  height: 56px;
  background: transparent;
  background: var(--light-grey);
  border: none;
  border-radius: 21px;

  @media (max-width: 765px) {
    width: 120px;
    max-width: 120px;
    height: 100%;
    height: 120px;
    max-height: 120px;
    border-radius: 50%;
  }
}
