.subscriptions-container {
  margin: 0 auto;
  padding: 40px 60px;

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    margin-right: 20px;
    margin-bottom: 60px;

    @media (max-width: 680px) {
      margin-bottom: 24px;
    }
  }

  &__button {
    width: 204px;

    @media (max-width: 680px) {
      position: absolute;
      top: 88px;
      right: 24px;
      width: unset;
      min-width: unset;
      height: unset;
      margin: 0;
      padding: 0;
      color: var(--dark-grey);
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      background: unset;

      &:hover {
        background: unset;
      }
    }
  }

  @media (max-width: 760px) {
    margin-top: 100px;
    padding: 24px;
  }
}
