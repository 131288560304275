.root {
  position: relative;
}

.container {
  display: flex;
  flex-direction: row;
  background: none;
  border: none;
  cursor: pointer;
}

.language {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  column-gap: 8px;
}

.dropdown {
  position: absolute;
  top: 30px;
  right: 0;
  padding: 4px 20px 4px 20px;
  background-color: var(--white);
  border-radius: 16px;
  box-shadow: 0 6px 32px rgba(23, 20, 52, 0.05);
}
