.section {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.content__conteiner {
  display: flex;
  flex-direction: column;
}

.title {
  margin-bottom: 8px;
  color: var(--secondary-black);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}

.content {
  color: var(--dark-grey);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.button__container {
  display: flex;
  height: fit-content;
}
