.letter {
  position: relative;
  display: flex;
  align-items: start;
  justify-content: space-between;
  width: 100%;
  border-radius: 24px;

  @media (max-width: 775px) {
    margin-bottom: 0;
  }

  &__content {
    display: flex;
    align-items: center;
  }

  &__button-delete {
    position: absolute;
    top: 50%;
    right: 0;
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
      transition: 0.7s;
    }
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px;
    margin-right: 16px;
    background: #f9f9f9;
    border-radius: 50%;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__has-chat {
    margin-bottom: 10px;
  }

  &__info-subtitle {
    color: var(--dark-grey);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  &__info-title {
    font-weight: 700;
    font-size: 16px;

    @media (max-width: 860px) {
      font-size: 16px;
    }
  }

  &__info-date {
    margin-top: 4px;
    margin-left: 8px;
    color: var(--dark-grey);
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  &__container {
    width: 100%;
    margin-bottom: 12px;
  }

  &__right {
    justify-content: end;
  }

  &__current {
    padding-left: 20px;
    border-left: 2px solid #646efa;
  }
}

.response {
  margin-top: 13px;
  background: transparent;
  border: none;
  outline: none;
  opacity: 0;
}
