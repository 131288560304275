.modal {
  &__name {
    margin-top: 48px;
    margin-bottom: 32px;
  }

  &__button {
    float: right;
    max-width: 200px;
    margin-top: 40px;

    @media (max-width: 968px) {
      float: unset;
      max-width: 100%;
    }
  }
}

.form {
  @media (max-width: 768px) {
    padding: 0 !important;
  }
}
