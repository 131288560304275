.chart-tooltip {
  padding: 8px 16px;
  color: var(--white);
  background-color: var(--background-black);
  border-radius: 8px;

  &__label {
    color: var(--white);
    text-align: center;
  }

  &__is-normal {
    &_normal {
      color: var(--background-secondary);
    }

    &_not-normal {
      color: var(--red);
    }
  }
}

.composed-chart {
  svg {
    height: 270px;
  }
}

.empty-message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% - 60px);
  height: 200px;
  margin-left: auto;
  font-size: 18px;

  &__text {
    padding: 20px;
    text-align: center;
    background: white;

    @media (max-width: 600px) {
      width: 250px;
      padding: 10px;
    }
  }
}
