.subscription {
  &__name {
    margin-bottom: 4px;
    text-align: left;

    @media (max-width: 640px) {
      width: 100%;
      margin-bottom: 24px;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__amount {
    text-align: right;

    @media (max-width: 640px) {
      position: relative;
      display: block;
      width: unset;
      margin-right: 12px;
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
      text-align: left;
    }
  }

  &__old-price {
    font-size: 18px;
    line-height: 24px;
    text-align: end;
    text-decoration: line-through;
    opacity: 0.6;

    @media (max-width: 640px) {
      display: none;
    }
  }

  &__old-price_mobile {
    position: absolute;
    top: -50%;
    left: 42%;
    padding-left: 10px;
    font-size: 16px;
    line-height: 22px;
    text-decoration: line-through;
    opacity: 0.6;

    @media (min-width: 640px) {
      display: none;
    }
  }

  &__description {
    color: var(--dark-grey);

    @media (max-width: 760px) {
      font-size: 14px;
      line-height: 18px;
    }

    @media (max-width: 640px) {
      order: 3;
      width: 100%;
    }
  }

  &__period {
    margin-left: auto;
    color: var(--dark-grey);
    text-align: end;

    @media (max-width: 760px) {
      margin-right: 12px;
      font-size: 14px;
      line-height: 18px;
    }

    @media (max-width: 640px) {
      margin-left: unset;
    }
  }

  &__features-toggler {
    display: none;

    @media (max-width: 640px) {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      width: 9px;
      height: 6px;
      transition: transform 0.2s;

      &_opened {
        transform: rotate(180deg);
      }

      path {
        transition: fill 0.2s;
      }

      &:hover {
        path {
          fill: var(--dark-grey);
        }
      }
    }
  }

  &-card {
    width: 100%;
    max-width: 420px;
    margin-right: 52px;
    padding: 40px 60px;
    background: var(--white);
    border-radius: 16px;
    box-shadow: 0 8px 52px #f3f3f3;
    transition: box-shadow 0.2s;

    &:last-child {
      margin-right: 0;
    }

    &__actions {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      & > button {
        min-width: 300px;
        height: 56px;

        &:first-child {
          margin-bottom: 20px;
        }
      }
    }

    &__header {
      position: relative;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      margin-bottom: 32px;

      @media (max-width: 970px) {
        align-items: center;
        justify-content: unset;
        margin-bottom: 20px;
      }
    }

    &__features {
      margin-bottom: 32px;
      color: var(--dark-grey);
      list-style: disc;
      list-style-position: inside;

      li {
        font-size: 14px;
        line-height: 18px;

        &:not(:last-of-type) {
          margin-bottom: 16px;
        }
      }

      @media (max-width: 640px) {
        max-height: 0;
        margin-bottom: 0;
        overflow: hidden;
        transition: max-height 0.4s, margin-bottom 0.6s;

        &_opened {
          max-height: var(--max-height);
          margin-bottom: 20px;
        }
      }
    }

    &:hover {
      box-shadow: 0 8px 52px var(--grey);
    }

    @media (max-width: 970px) {
      max-width: 500px;
      margin: auto;
      margin-bottom: 20px;

      &:last-child {
        margin-right: auto;
        margin-bottom: 0;
      }
    }

    @media (max-width: 640px) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 24px;
      padding: 20px 24px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.subscription_title {
  display: flex;
  justify-content: space-between;
  column-gap: 12px;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }

  h3 {
    max-width: max-content;
  }
}

.subscription_subheader {
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 100%;
    white-space: nowrap;
  }
}
