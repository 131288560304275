.tabs {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 32px;

  @media (max-width: 1210px) {
    display: flex;
  }

  &::before {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 4px;
    background: var(--grey);
    border-radius: 16px;
    content: '';
  }

  ul {
    width: 100%;
    cursor: pointer;
  }

  &__tab {
    position: relative;
    display: flex;
    align-items: start;
    justify-content: center;
    width: 100%;
    color: var(--dark-grey);
    font-weight: 500;
  }

  &__button {
    width: 100%;
    padding-bottom: 20px;
    background: var(--white);
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__button_active-left {
    &::before {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 50%;
      height: 4px;
      background: #646efa;
      border-radius: 16px;
      cursor: pointer;
      content: '';
    }

    pointer-events: none;
  }

  &__button_active-right {
    pointer-events: none;

    &::before {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 50%;
      height: 4px;
      background: #646efa;
      border-radius: 16px;
      content: '';
    }
  }

  &__active {
    color: #646efa;
  }
}
