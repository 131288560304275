.delete__button {
  display: none;
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  appearance: none;

  @media (max-width: 768px) {
    display: block;
  }
}

.analysis-card {
  display: flex;
  align-items: center;
  width: 100%;
  min-height: 100px;
  padding: 0 24px;
  background: #fff;
  border: none;
  border-radius: 24px;
  box-shadow: 0 8px 52px #f3f3f3;
  cursor: pointer;
  appearance: none;

  @media (max-width: 760px) {
    height: 88px;
    min-height: 88px;
    padding: 0 20px;
    box-shadow: 0 8px 52px #f3f3f3;
  }

  :hover {
    .delete__button {
      display: block;
    }
  }
}

.icon {
  width: 56px;
  min-width: 56px;
  height: 56px;
  margin-right: 16px;
  background-color: var(--lighter-grey);
  border-radius: 50%;

  @media (max-width: 760px) {
    width: 48px;
    min-width: 48px;
    height: 48px;
  }
}

.inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.content {
  width: 100%;

  &-date {
    margin-bottom: 12px;
    color: var(--secondary-black);
  }

  &-age {
    max-width: 240px;
    color: var(--dark-grey);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  @media (max-width: 760px) {
    &-date {
      margin-bottom: 10px;
    }

    &-age {
      max-width: unset;
    }
  }

  @media (max-width: 1440px) {
    &-age {
      max-width: auto;
    }
  }
}
