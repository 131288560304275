.root {
  display: flex;
  flex-direction: column;
}

.header_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
}

.header {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
}

.change_card {
  color: var(--background-primary);
  font-weight: 500;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  transition: color 0.4s ease;

  &:hover {
    color: var(--dark-grey);
  }

  &:disabled {
    color: var(--dark-grey);
  }
}

.content_container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media (max-width: 760px) {
    grid-template-columns: 1fr;
  }
}

.content {
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  @media (max-width: 640px) {
    row-gap: 4px;
  }
}

.description_text {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: 640px) {
    font-size: 20px;
    line-height: 24px;
  }
}

.access_text {
  color: var(--blue);
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;

  @media (max-width: 640px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}

.info {
  display: flex;
  flex-direction: column;
  align-self: center;
  text-align: end;

  @media (max-width: 640px) {
    margin-top: 16px;
    text-align: left;
  }
}

.info_text {
  color: var(--dark-grey);
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
}

.price_container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;
  column-gap: 6px;

  @media (max-width: 640px) {
    justify-content: flex-start;
    margin-bottom: 4px;
  }
}

.price {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  @media (max-width: 640px) {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  margin-top: 32px;
  padding-top: 32px;
  border-top: 2px solid var(--light-grey);

  @media (max-width: 760px) {
    flex-direction: column;
    row-gap: 12px;
  }
}

.subscription__button {
  width: 200px;
  white-space: nowrap;

  &:nth-child(1) {
    margin-right: 17px;
  }

  @media (max-width: 890px) {
    &:nth-child(1) {
      margin-right: unset;
      margin-bottom: 20px;
    }

    width: 100%;
  }

  @media (max-width: 760px) {
    &:nth-child(1) {
      margin-bottom: unset;
    }
  }

  &_renew {
    width: 248px;

    @media (max-width: 540px) {
      width: 100%;
    }
  }
}

.notion {
  margin-top: 50px;
  color: var(--secondary-red);
}
