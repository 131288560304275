.patient-card {
  width: auto;
  margin-right: 25px;
  margin-bottom: 16px;
  margin-left: 25px;
  padding: 16px;
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    @media (max-width: 1099px) and (min-width: 820px) {
      flex-direction: row;
      justify-content: flex-start;
    }

    &__item {
      @media (max-width: 1099px) and (min-width: 820px) {
        flex: 1 1 50%;
      }

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  &__user {
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 20px;
  }

  &__user-icon {
    min-width: 42px;
    height: 42px;
    background-color: var(--lighter-grey);
    border-radius: 16px;
    cursor: pointer;
  }

  &__user-name {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-left: 16px;
    text-align: start;
    overflow-wrap: anywhere;
  }

  &__date-text {
    margin-top: 4px;
    color: var(--dark-grey);
  }

  &__grey-text {
    margin-bottom: 4px;
    color: var(--dark-grey);
  }

  &__dot {
    width: 10px;
    height: 10px;
    background: #35b185;
  }

  &__link {
    list-style: none;
    background: transparent;
    border: none;
  }

  &__column {
    margin-bottom: 16px;
  }
}

.requests__buttons {
  display: grid;
  flex-wrap: nowrap;
  grid-template-columns: 46px auto;
  width: 100%;
  column-gap: 12px;
}

.decline__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: unset;
  max-width: 52px;
  padding: 0;
}

.cell__dot {
  min-width: 10px;
  min-height: 10px;
  margin-right: 10px;
  background: #35b185;
  border-radius: 50%;

  &_grey {
    min-width: 10px;
    min-height: 10px;
    margin-right: 10px;
    background: var(--light-grey);
    border-radius: 50%;
  }
}

.text_grey {
  color: var(--dark-grey);
}
