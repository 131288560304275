.expert-info-modal {
  &__username_container {
    display: flex;
    flex-wrap: nowrap;
    column-gap: 4px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 12px;
    background: var(--white);

    @media (max-width: 768px) {
      margin-top: unset;
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: center;
  }

  &__avatar {
    display: flex;
    width: 160px;
    height: 160px;
    object-fit: cover;

    @media (max-width: 768px) {
      width: 100px;
      height: 100px;
    }

    img {
      width: 160px;
      height: 160px;
      object-fit: cover;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 100px;
        height: 100px;
      }
    }
  }

  &__expert-name {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
  }

  &__info-wrapper {
    display: grid;
    grid-template-columns: 63px 1fr 1fr;
    width: 100%;
    margin: 32px 0;
    padding-right: 59px;
    column-gap: 80px;

    @media (max-width: 768px) {
      grid-template-columns: 63px 1fr;
      column-gap: 32px;
      row-gap: 24px;
    }
  }

  &__info-item-container {
    display: flex;
    flex-direction: column;
  }

  &__info-label {
    min-width: max-content;
    margin-bottom: 12px;
    color: var(--dark-grey);
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  &__info-item {
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
  }

  &__short-info-container {
    width: 100%;
  }

  &__short-info {
    max-height: 120px;
    overflow-y: scroll;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.01em;
  }

  &__actions-container {
    display: flex;
    width: 100%;
    margin-top: 32px;
    margin-bottom: 12px;
    column-gap: 8px;

    @media (max-width: 768px) {
      flex-direction: column;
      row-gap: 8px;
    }
  }

  &__chat-icon {
    width: 21px;
    height: 21px;

    @media (max-width: 768px) {
      margin-right: 10px;
    }
  }

  &__button-text {
    color: var(--secondary-black);
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
  }
}
