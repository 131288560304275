.root {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  padding: 16px 0;
  cursor: default;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 20px;
  background: none;
  border: none;
  cursor: pointer;
}

.language {
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 6px;
}

.check {
  height: 20px;

  svg > path {
    fill: var(--purple);
  }
}
