.Toastify {
  &__toast {
    background-color: var(--white);
    border-radius: 8px;
    box-shadow: 0 0 10px #f1f1f1;

    &-container {
      width: 480px;
      padding-left: 24px;

      @media (max-width: 580px) {
        width: 100%;
        padding: 24px 24px 0;
      }

      &--bottom-left {
        bottom: 0;
        left: 0;

        @media (max-width: 760px) {
          bottom: 68px;
        }
      }
    }

    @media (max-width: 760px) {
      margin-bottom: 8px;
    }
  }

  &__close {
    &-button {
      color: var(--secondary-black);
    }
  }
}
