.form-error {
  margin-top: 4px;

  &__message {
    margin-top: 4px;

    &_error {
      color: var(--red);
      font-weight: 500;
    }

    &_warning {
      color: var(--yellow);
    }
  }
}

.responsive-form-error {
  @media (max-width: 640px) {
    display: none;
  }
}
