.card {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: var(--secondary-black);
  font-weight: bold;
  font-size: 20px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  line-height: 24px;
  letter-spacing: -0.01em;
  background: var(--white);
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  &_small-height {
    min-height: 206px;

    @media (max-width: 600px) {
      min-height: 200px;
    }
  }

  &_big-height {
    min-height: 212px;

    @media (max-width: 600px) {
      min-height: 169px;
    }
  }

  @media (max-width: 600px) {
    border-radius: 2px 16px 16px 2px;
    box-shadow: 0 0 10px #f1f1f1;
  }

  &_big {
    padding: 36px;

    @media (max-width: 600px) {
      padding: 16px;
    }
  }

  &_small {
    padding: 16px 20px 20px;
  }

  .title {
    overflow-wrap: break-word;

    &_big {
      font-size: 28px;
      line-height: 36px;
    }

    &_small {
      font-size: 20px;
      line-height: 24px;
    }

    @media (max-width: 600px) {
      &_big {
        font-size: 18px;
        line-height: 22px;
      }

      &_small {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .content {
    display: -webkit-box;
    margin-top: 12px;
    font-weight: normal;

    &_big {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    &_small {
      font-size: 14px;
      line-height: 18px;
    }

    @media (max-width: 600px) {
      &_big {
        font-size: 14px;
        line-height: 18px;
      }

      &_small {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  .tooltip {
    margin-left: 4px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    margin-top: auto;

    @media (max-width: 600px) {
      &__hide-text {
        flex-direction: row;
        align-items: center;
      }
    }
  }

  .scale-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__scale {
      width: 100%;
      height: 14px;
      overflow: hidden;
      background: var(--light-grey);
      border-radius: 4px;

      &-inner {
        height: 100%;
        background: var(--background-primary);
        border-radius: 4px;
        transition: 0.3s;
      }
    }

    &__value {
      margin-left: 10px;
      color: #404040;
    }
  }

  .link-more {
    display: flex;
    margin-top: 17px;

    &__link {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
      align-items: center;
      -webkit-justify-content: center;
      justify-content: flex-start;
      color: var(--secondary-black);
      font-weight: bold;
      font-size: 14px;
      line-height: 18px;
      text-decoration: none;

      svg {
        width: 15px;
        height: 12px;
        margin-left: 12px;

        path {
          fill: var(--secondary-black);
        }
      }
    }

    @media (max-width: 600px) {
      &_hide {
        margin-top: unset;
      }

      &__text_hide {
        display: none;
        margin-left: 24px;
      }
    }
  }
}

.date_container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}

.date {
  font-weight: 400;
  font-size: 14px;

  @media (max-width: 600px) {
    font-size: 12px;
  }
}
