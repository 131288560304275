.cancel-subscription-modal {
  &__text {
    padding: 16px 0 40px;
    color: var(--dark-grey);
  }

  &__button {
    width: 177px;

    @media (max-width: 768px) {
      width: 100%;
      min-width: unset;
    }
  }
}
