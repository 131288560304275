.aside_container {
  display: flex;
  flex: 1;
  gap: 24px;
  height: 100%;
  padding: 0 60px 60px 60px;

  @media (max-width: 970px) {
    padding-right: 30px;
    padding-bottom: 30px;
    padding-left: 30px;
  }

  @media (max-width: 640px) {
    padding-right: 0;
    padding-bottom: 0;
    padding-left: 0;
  }
}

.message {
  &__mobile_chat {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-decoration: none;
  }
}
