.empty {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 180px;
  padding-bottom: 180px;
  text-align: center;

  @media (max-width: 768px) {
    padding-top: 120px;
    padding-bottom: 120px;
  }
}

.empty__icon {
  width: 120px;
  height: 120px;
  margin-bottom: 20px;

  @media (max-width: 768px) {
    width: 64px;
    height: 64px;
  }
}

.empty__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty__subtitle {
  margin-bottom: 32px;
  color: var(--dark-grey);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
