.form-label {
  display: flex;
  gap: 14px;
  align-items: center;
  margin-bottom: 12px;

  &__text {
    color: var(--black);

    //@media (max-width: 760px) {
    //  max-width: 16.47rem;
    //}
  }

  &__link {
    color: var(--secondary-black);
    font-weight: 600;
    text-decoration: none;
    transition: color 0.2s;

    &:hover {
      color: var(--dark-grey);
    }
  }

  &__tooltip {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media (max-width: 640px) {
    &__link {
      font-size: 14px;
      line-height: 18px;
    }
  }
}
