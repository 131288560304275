.chat {
  width: 100%;
  height: auto;
  background: #fff;
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 976px) {
    // height: 100vh;
    margin-right: 0;
  }

  @media (max-width: 640px) {
    // height: calc(100vh - 118px);

    border-radius: 0;
  }

  &__empty-chat {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &__link_button {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    margin-left: 24px;
    background: transparent;
    border: none;
    outline: none;
  }

  &__link-back {
    margin-left: 11px;
    color: var(--dark-grey);
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }

  &__empty {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__header {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 86px;
    background: #fff;
    border-radius: 16px 16px 0 0;
    box-shadow: 0 8px 52px #f3f3f3;

    @media (max-width: 640px) {
      justify-content: flex-start;
      padding-left: 20px;
      box-shadow: 0 4px 24px rgba(37, 37, 37, 0.04);
    }
  }

  &__text-message_title {
    color: var(--secondary-black);
    font-weight: 500;
    font-size: 16px;
    letter-spacing: -0.01em;

    a {
      cursor: pointer;
    }
  }

  &__text-message_body {
    color: var(--dark-grey);
  }

  &__submitted {
    position: relative;
    display: flex;
    width: 100%;
    margin-top: 24px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100% - 100px);
    padding: 0 32px 32px 32px;
    overflow: hidden;

    // @media (max-width: 640px) {
    //   padding: 32px 24px 24px 16px;
    //   height: calc(100% - 124px);
    // }

    @media (max-width: 640px) {
      position: sticky;
      bottom: calc(68px + 16px);
      width: 100%;
      padding: 0 24px 16px 24px;
      // height: calc(100% - 124px);
    }
  }

  &__clip {
    position: absolute;
    top: 17px;
    left: 20px;
    background: transparent;
    border: none;
  }

  &__more {
    position: absolute;
    top: 30px;
    right: 30px;
    background: white;
    border: none;
    outline: none;
  }

  &__messages-date-separator {
    width: 100%;
    padding: 27px 0 3px 0;
    color: var(--dark-grey);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: center;

    @media (max-width: 768px) {
      padding: 8px 0 18px 0;
    }
  }
}

.chat-mobile {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  border-radius: 24px;

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    min-width: 56px;
    height: 56px;
    margin-right: 16px;
    background: var(--dark-grey);
    border-radius: 50%;
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__info-subtitle {
    color: var(--dark-grey);
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: left;
  }

  &__info-title {
    color: var(--secondary-black);
    font-weight: 700;
    font-size: 16px;

    a {
      cursor: pointer;
    }

    @media (max-width: 860px) {
      font-size: 16px;
    }

    @media (max-width: 768px) {
      overflow-wrap: anywhere;
    }
  }

  &__button_profile {
    max-width: 80%;
    background: transparent;
    border: none;
    outline: none;
  }

  &__input_file {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 56px;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }
}

.empty-message {
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: calc(100vh - 254px);
  margin-right: 60px;
  margin-bottom: 48px;
  margin-left: 60px;
  background: var(--white);
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 768px) {
    height: calc(100vh - 74px);
    margin: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__text {
    margin-top: 28px;
    margin-bottom: 40px;
    color: var(--dark-grey);
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60vh;
}

.scrollToBottom {
  position: absolute;
  right: 0;
  bottom: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 52px;
  min-height: 52px;
  list-style: none;
  background-color: var(--light-grey);
  border: none;
  border-radius: 50%;
  cursor: pointer;
}
