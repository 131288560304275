.history {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 780px;
  margin-top: 20px;

  &__sort {
    display: flex;
    gap: 13px;

    @media (max-width: 1160px) {
      justify-content: space-between;
      width: 100%;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;

    @media (max-width: 1160px) {
      flex-direction: column;
      gap: 16px;
      align-items: flex-start;
    }
  }

  &_loading {
    opacity: 0.6;
  }

  @media (max-width: 840px) {
    &__button {
      width: 100% !important;
    }
  }
}

.container {
  height: 100%;
  border-radius: 16px;

  @media (max-width: 800px) {
    margin-bottom: 20px;
  }
}

.item {
  @media (max-width: 1410px) {
    width: 100%;
  }
}

.list {
  display: grid;
  grid-row-gap: 32px;
  grid-column-gap: 32px;
  grid-template-columns: 1fr 1fr;
  padding-right: 48px;
  padding-bottom: 48px;
  padding-left: 48px;

  @media (min-width: 640px) and (max-width: 760px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
    padding: unset;
  }

  @media (min-width: 760px) and (max-width: 1270px) {
    grid-template-columns: 1fr;
  }
}

.waiting {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 600px;
  padding: 60px 80px;

  @media (max-width: 840px) {
    padding: unset;
    box-shadow: unset;
  }
}

.tabs__container {
  display: flex;
  padding: 14px 48px 36px 48px;

  @media (max-width: 768px) {
    padding: 0 0 22px 0;
  }
}

.button__container {
  padding-top: 24px;
  padding-left: 48px;

  @media (max-width: 768px) {
    padding: 0;
  }
}
