.base-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(15, 30, 38, 0.6);

  &__title {
    @media (max-width: 760px) {
      font-weight: bold;
      font-size: 24px;
      line-height: 32px;
    }
  }

  &__body {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 571px;
    margin: auto;
    padding: 48px;
    background-color: var(--white);
    border-radius: 12px;
    outline: none;

    @media (max-width: 968px) and (orientation: landscape) {
      max-height: 88%;
      overflow-y: scroll;
    }

    @media (max-width: 968px) and (orientation: portrait) {
      max-height: 80%;
      overflow-y: scroll;
    }

    @media (max-width: 760px) {
      padding: 60px 24px 24px 24px;
    }

    @media (max-width: 420px) {
      max-height: 88%;
      overflow-y: scroll;
    }
  }

  &__close-icon {
    position: absolute;
    top: 21.64px;
    right: 21.64px;
    cursor: pointer;

    path {
      transition: fill 0.2s;
    }

    &:hover {
      path {
        fill: var(--dark-grey);
      }
    }
  }

  &__description {
    margin-top: 16px;
    margin-bottom: 40px;
    color: var(--dark-grey);
  }
}

.without-background {
  background: transparent !important;
}
