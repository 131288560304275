.user-info-form {
  flex-wrap: wrap;
  margin-bottom: 16px;

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    min-height: 358px;
  }

  &__input {
    flex-basis: 49%;
    box-sizing: border-box;
    margin-bottom: 24px;

    label {
      display: block;
      margin-bottom: 12px;
      font-family: 'Inter', sans-serif;
      letter-spacing: -0.01em;
    }

    @media (max-width: 1100px) {
      flex-basis: 100%;
    }
  }

  &__buttons {
    flex-wrap: wrap;
    justify-content: flex-end;

    @media (max-width: 1100px) {
      width: 100%;

      button {
        width: 100% !important;

        &:last-child {
          margin-top: 20px !important;
        }
      }
    }

    @media (max-width: 760px) {
      button {
        &:last-child {
          margin-top: unset !important;
        }
      }
    }
  }

  &__title {
    display: none;

    @media (max-width: 760px) {
      display: block;
      margin-bottom: 29px;
    }
  }

  &__remove-button {
    @media (max-width: 760px) {
      display: none;
    }
  }

  &__input-error {
    margin-bottom: 24px;
    color: var(--red);
  }

  @media (max-width: 760px) {
    align-content: flex-start;
  }
}
