.anamnesis {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: space-between;
  }

  &__input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
  }

  &-form {
    &__block {
      &:not(:first-of-type) {
        // margin-bottom: 32px;
        padding-top: 32px;
        border-top: 1px solid var(--light-grey);
      }

      &:not(:last-of-type) {
        padding-bottom: 32px;
        // border-bottom: 1px solid var(--light-grey);
      }
    }
  }
}

.progress {
  display: flex;
  flex-direction: column;

  &__total {
    align-self: flex-end;
    color: var(--dark-grey);
  }

  &__filled-indicators {
    display: flex;
    align-items: center;
    margin-top: 12px;

    @media (max-width: 960px) {
      width: 100%;
    }
  }

  &__filled-indicator {
    width: 80px;
    height: 8px;
    background: var(--grey);
    border-radius: 4px;
    box-shadow: inset 0 0 0 0 #646efa;
    cursor: pointer;
    transition: all ease 0.8s;

    &:not(:last-child) {
      margin-right: 8px;
    }

    @media (max-width: 960px) {
      width: 60px;
    }

    @media (max-width: 500px) {
      width: 40px;
    }

    &_filled {
      //background: var(--background-primary);
      box-shadow: inset 100px 0 0 0 #646efa;
      transition: all ease 0.8s;
    }

    @media (max-width: 500px) {
      width: 40px;
    }
  }
}

.common-info {
  display: flex;
  flex-direction: column;
  padding-top: 28px;

  &__photo {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__photo-upload {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 18px;
  }

  &__fields {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 32px;

    @media (max-width: 480px) {
      margin-top: 0;
    }
  }

  &__field {
    width: 48%;

    @media (max-width: 1000px) {
      width: 100%;
    }
  }
}

.health-checks {
  &__inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  &__text-ares {
    // display: flex;
    // align-items: flex-end;
    // justify-content: space-between;
    // flex-wrap: wrap;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;
    align-items: flex-start;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }

  &__radio-group {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  &__input-field {
    width: calc(100% / 5 - 32px);
    min-width: 152px;

    @media (max-width: 1368px) {
      &:not(:last-child) {
        margin-bottom: 32px;
      }

      min-width: 48%;

      &:last-child {
        width: 100%;
      }
    }
  }

  &__field {
    display: grid;

    /* justify-items: stretch; */
    align-content: space-between;
  }
}

.diseases-checks {
  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }

  &__field-group {
    display: grid;
    grid-gap: 32px;
  }
}

.health-questions {
  &__fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 32px;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }
}

.cycle {
  display: flex;
  flex-wrap: wrap;
  column-gap: 32px;
  row-gap: 32px;

  @media (max-width: 640px) {
    // grid-template-columns: 1fr;
  }

  &__field {
    &_duration {
      display: flex;
      flex-direction: column;
    }
  }
}

.checkbox-group {
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: max-content max-content;
  row-gap: 24px;
}

.radio-group {
  // display: grid;
  // grid-column-gap: 24px;
  // grid-template-columns: min-content min-content 1fr;
  // row-gap: 24px;
  // align-items: center;
  // flex-wrap: wrap;
  // justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  column-gap: 24px;
  row-gap: 24px;

  @media (max-width: 640px) {
    grid-template-columns: 133px 1fr;
  }
}

.double-radio-group {
  display: flex;
  gap: 32px;
}

.large-text-radio-group {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 24px;

  @media (max-width: 640px) {
    grid-template-columns: 1fr;
  }
}

.optional-textarea {
  grid-row: 3;
  grid-column: span 3;

  @media (max-width: 640px) {
    grid-area: 3 / span 2 / auto / auto;
  }

  &__column {
    grid-row: 5;
    grid-column: span 1;
  }
}

.save-button {
  width: 200px;
  margin-top: 40px;

  @media (max-width: 560px) {
    width: 100%;
  }
}
