.document-status {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  max-width: 354px;
  margin-right: 60px;
  padding: 22px 26px;
  border-radius: 24px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 968px) {
    width: auto;
    margin-top: 32px;
    margin-left: 60px;
  }

  @media (max-width: 768px) {
    margin-top: 32px;
    margin-left: 24px;
  }

  @media (max-width: 500px) {
    width: auto;
    max-width: 100%;
    margin-right: 24px;
  }

  &__circle {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 56px;
    min-height: 56px;
    margin-right: 16px;
    background: #f9f9f9;
    border-radius: 50%;

    @media (max-width: 768px) {
      min-width: 48px;
      min-height: 48px;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
  }

  &__info-subtitle {
    color: var(--dark-grey);
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    @media (max-width: 970px) {
      white-space: pre;
    }
  }

  &__info-title {
    margin-bottom: 12px;
    font-weight: 600;

    @media (max-width: 970px) {
      font-size: 16px;
      white-space: pre;
    }
  }
}
