.search {
  position: relative;
  width: 100%;
  max-width: 366px;
  height: 52px;

  @media (max-width: 768px) {
    max-width: 100%;
    margin-right: 24px;
    margin-left: 24px;
  }

  &__title {
    margin-left: 60px;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__title_mobile {
    display: none;
    margin-right: 60px;
    margin-left: 60px;

    @media (max-width: 768px) {
      display: block;
      margin-top: 32px;
      margin-right: 24px;
      margin-left: 24px;
    }
  }

  &__container {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-end;
    width: auto;
    margin-right: 60px;
  }

  &__box {
    display: flex;
    justify-content: start;
    width: 100%;

    @media (max-width: 968px) {
      display: none;
    }
  }

  &__box_mobile {
    display: none;

    @media (max-width: 968px) {
      display: flex;
      margin-top: 24px;
      margin-bottom: 24px;
      margin-left: 60px;
    }

    @media (max-width: 768px) {
      margin-left: 0;
    }
  }

  &__icon {
    position: absolute;
    top: 16px;
    left: 18px;
  }

  &__button-box {
    box-sizing: border-box;
    width: 100%;
    max-width: 188px;
  }

  &__subtitle {
    margin-left: 10px;
  }
}

.filters {
  width: 270px;
  max-width: 270px;
  margin-top: 14px;

  @media (max-width: 968px) {
    display: none;
    margin-left: 60px;
  }

  @media (max-width: 768px) {
    margin-left: 24px;
  }
}

.filters-mobile {
  display: none;

  @media (max-width: 968px) {
    display: block;
    max-width: 366px;
    margin-right: 24px;
    margin-left: 60px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin-left: 24px;
  }
}
