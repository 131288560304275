.biomarkers-item {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid var(--light-grey);

  &:nth-last-of-type(2) {
    border-bottom: none;
  }

  @media (max-width: 767px) {
    padding: 16px 0 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    border-bottom: none;
  }

  &__title {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 767px) {
      display: -webkit-box;
      //height: none;
      white-space: unset;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__unit {
    display: flex;
    justify-content: center;
    //padding-left: 30px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-word;

    @media (max-width: 767px) {
      display: -webkit-box;
      padding-left: 0;
      overflow: hidden;
      white-space: unset;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__footnote {
    color: var(--dark-grey);

    &-norm {
      white-space: nowrap;
    }
  }

  &__previous-value {
    &-wrapper {
      @media (max-width: 767px) {
        min-height: 36px;
        padding: 9px 12px 9px 12px;
      }
    }
  }

  &__value {
    &-wrapper {
      @media (max-width: 767px) {
        display: -webkit-box;
        min-height: 36px;
        padding: 9px 12px 9px 12px;
        background-color: var(--light-grey);
        border-radius: 12px;
      }
    }

    &--value {
      margin-right: 4px;
    }
  }

  &__hide-value {
    @media (max-width: 767px) {
      display: none;
    }
  }

  &__norm {
    color: var(--dark-grey);
  }
}

.out-of-norm-value {
  color: var(--red);
}

.norm-value {
  color: var(--background-secondary-dark);
}

.unknown-value {
  color: var(--black);
}

.difference-icon {
  svg {
    width: 11px;
    height: 11px;
  }

  &--increased {
    transform: rotate(-90deg);
  }

  &--decreased {
    transform: rotate(90deg);
  }
}
