.arrow-back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 52px;
  height: 52px;
  background-color: var(--light-grey);
  border: none;
  border-radius: 16px;
  transform: rotate(180deg);
  cursor: pointer;

  svg {
    width: 15px;
    height: 12px;

    path {
      transition: 0.2s;
      fill: var(--secondary-black);
    }
  }

  &:hover {
    svg path {
      fill: var(--black);
    }
  }

  @media (max-width: 640px) {
    svg {
      width: 12.5px;
      height: 10px;
    }
  }
}
