.success-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  &__text {
    margin-bottom: 35px;

    @media (max-width: 500px) {
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
    }
  }

  &__text_grey {
    max-width: 371px;
    margin-top: 40px;
    color: #8e8e8e;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.01em;
    text-align: center;
  }

  &__button {
    max-width: 371px;
    margin-top: 40px;
  }

  &__icon {
    width: 96px;
    height: 96px;

    @media (max-width: 500px) {
      width: 60px;
      height: 60px;
    }
  }
}
