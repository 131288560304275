.expert {
  &__header {
    display: flex;
    column-gap: 4px;
  }

  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr 50px;
    justify-content: space-between;
    width: 100%;
    column-gap: 16px;
    filter: drop-shadow(0 8px 52px #f3f3f3);
  }

  &__wrapper {
    display: flex;
    width: fit-content;
  }

  &__card {
    display: grid;
    grid-template-columns: 120px auto;
    padding: 24px;
    column-gap: 16px;
    background: var(--white);
    border: 2px solid var(--white);
    border-radius: 24px;

    &:hover {
      border: 2px solid var(--background-primary-lighter);
      cursor: pointer;
    }

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      max-width: 100%;
      padding: 0;
      overflow: hidden;
      border-radius: unset;
    }
  }

  &__card_dashboard {
    display: grid;
    grid-template-columns: 120px auto;
    padding: 24px;
    column-gap: 16px;
    background: var(--white);
    border-radius: 24px;

    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      min-width: 360px;
      max-width: 100%;
      overflow: hidden;
      border-radius: 24px;
    }
  }

  &__card_info-modal-opened {
    background: var(--background-primary-lighter);
    border: 2px solid var(--background-primary-lighter);
  }

  &__avatar {
    flex: 1 1 120px;
    width: 120px;
    height: 120px;
    margin-right: 16px;

    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 50%;
    }

    @media (max-width: 768px) {
      flex: 1 1 48px;
      width: 48px;
      height: 48px;
      margin-right: 12px;

      img {
        width: 48px;
        height: 48px;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    width: fit-content;

    @media (max-width: 768px) {
      &_row {
        display: grid;
        grid-template-columns: 48px auto;
        column-gap: 12px;
      }
    }

    @media (max-width: 576px) {
      max-width: 420px;
    }

    @media (max-width: 381px) {
      max-width: 320px;
    }

    @media (max-width: 350px) {
      max-width: 200px;
    }
  }

  &__username {
    max-width: 250px;
    margin-bottom: 8px;
    overflow: hidden;
    color: var(--black);
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 768px) {
      max-width: 220px;
      font-size: 14px;
      line-height: 18px;
    }
  }

  &__info {
    max-width: 250px;
    margin-bottom: 16px;
    overflow: hidden;
    color: var(--black);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;

    @media (max-width: 768px) {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
    }
  }

  &__actions {
    display: flex;

    @media (max-width: 768px) {
      display: grid;
      grid-template-columns: 46px auto;
      column-gap: 8px;
    }
  }

  &__chat-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 52px;
    height: 52px;
    margin-right: 8px;
    background: var(--light-grey);
    border: none;
    border-radius: 16px;
    outline: none;
    cursor: pointer;
    transition: all 0.1s;

    &:hover {
      background: var(--grey);
    }

    @media (max-width: 768px) {
      width: 46px;
      height: 46px;
    }
  }

  &__chat-icon {
    width: 21px;
    height: 21px;
  }
}
