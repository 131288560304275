.waiting {
  width: 100%;
  margin-top: 40px;

  &__title {
    margin-bottom: 24px;
  }

  &__text {
    //max-width: 692px;
    margin-bottom: 20px;
    white-space: pre-line;
  }
}
