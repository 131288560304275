.subscriptions-container {
  width: 100%;
  margin: 0 auto;
  padding: 40px 0;

  &__header {
    display: flex;
    justify-content: space-between;

    @media (max-width: 760px) {
      flex-direction: column;
      row-gap: 12px;
    }
  }

  &__button {
    width: 204px;

    @media (max-width: 680px) {
      position: absolute;
      top: 88px;
      right: 24px;
      width: unset;
      min-width: unset;
      height: unset;
      margin: 0;
      padding: 0;
      color: var(--dark-grey);
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      background: unset;

      &:hover {
        background: unset;
      }
    }
  }

  @media (max-width: 760px) {
    width: 100%;
    margin-top: 100px;
    padding: 24px;
  }

  @media (min-width: 1320px) {
    width: 1320px;
  }
}

.active_promocode_container {
  display: flex;
  align-items: center;
}

.active_promocode {
  color: var(--blue);
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
}
