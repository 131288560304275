.card {
  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    max-width: 297px;
    height: 100%;
    height: 154px;
    padding: 16px 20px;
    background: #fff;
    border-radius: 16px;

    @media (max-width: 768px) {
      min-width: 297px;
    }
  }

  &__header {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  &__body {
    font-size: 12px;
    line-height: 16px;
  }

  &__link {
    color: var(--background-secondary);
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}
