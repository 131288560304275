$block: '.dashboard';

#{$block} {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 28px 60px 80px;

  &__aside-button {
    color: var(--dark-grey);
    font-size: 14px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
  }

  &__wrapper {
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
  }

  &__main {
    display: flex;
    flex-direction: column;
    width: 72%;
    max-width: 72%;

    @media (max-width: 1480px) {
      width: 100%;
      max-width: 100%;
      margin-bottom: 24px;
    }

    @media (max-width: 760px) {
      margin-bottom: unset;
    }
  }

  &-user-info {
    display: flex;
    justify-content: space-between;

    &__subtitle {
      margin-top: 12px;
      color: var(--dark-grey);
    }

    &__main {
      margin-right: 20px;
    }

    &__title {
      width: 48vw;
      max-width: 760px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    @media (max-width: 760px) {
      flex-wrap: wrap;
      justify-content: flex-start;
      width: 100%;

      &__title {
        width: 85vw;
        max-width: 85vw;
        margin-right: 14px;
        overflow: hidden;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        text-overflow: ellipsis;
      }

      &__subtitle {
        margin-top: 8px;
        font-weight: normal;
      }

      &__main {
        margin-right: unset;
      }
    }
  }

  &-age-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 300px;
    height: 76px;
    padding: 20px;
    background: var(--white);
    border-radius: 12px;
    box-shadow: 0 8px 52px #f3f3f3;

    &__info {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: space-between;
      width: 100%;
    }

    &__title {
      font-weight: 700;
      font-size: 14px;
      line-height: 18px;
      word-wrap: break-word;

      @media (max-width: 768px) {
        display: flex;
        align-items: center;
      }
    }

    &__age {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      color: var(--background-secondary);

      @media (max-width: 760px) {
        font-size: 24px;
        line-height: 32px;
      }
    }

    &__tabs {
      display: flex;
      justify-content: space-between;
      margin-left: 10px;
    }

    &__tab {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      &-button {
        width: 14px;
        height: 41px;
        margin-top: auto;
        background: var(--background-primary-lighter);
        border-radius: 4px;
        cursor: pointer;
        transition: background 0.2s;

        &_active {
          background: var(--background-primary);
        }
      }

      &-date {
        margin-top: 8px;
        padding: 0 1px;
        color: #b4b4b4;

        &_active {
          color: var(--secondary-black);
        }
      }

      &:nth-child(2) {
        .dashboard-age-card__tab-button {
          height: 52px;
        }
      }
    }

    @media (max-width: 760px) {
      width: 100%;
      height: 64px;
      margin-top: 20px;
      box-shadow: 0 8px 52px #f3f3f3;
    }
  }

  &-pay-attention {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 197px;
    margin-top: 46px;

    &__container {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 24px;
    }

    &__title {
      @media (max-width: 760px) {
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__waiting {
      width: 100%;
      height: 197px;
      margin: 24px 0;

      @media (max-width: 600px) {
        height: 156px;
        font-weight: 600;
        font-size: 18px;
        line-height: 22px;
      }
    }

    &__show-all {
      margin-left: auto;
      color: var(--dark-grey);
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      transition: 0.2s;

      p {
        color: var(--dark-grey);
      }

      &:hover {
        color: var(--black);
      }

      @media (max-width: 768px) {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        width: 100%;
      }
    }

    @media (max-width: 760px) {
      &__show-all {
        order: 2;
        margin-bottom: 16px;
        margin-left: unset;
      }
    }
  }

  &__attentions {
    display: flex;
    width: 100%;
    height: 100%;
    margin-bottom: 24px;
    column-gap: 16px;
    filter: drop-shadow(0 8px 52px #f3f3f3);

    @media (max-width: 768px) {
      overflow-y: scroll;
    }
  }

  &-empty-attentions {
    width: 100%;
    height: 197px;
    margin: 24px 0;
    padding: 44px;
    background: var(--white);
    border-radius: 16px;
    box-shadow: 0 8px 52px #f3f3f3;

    @media (max-width: 600px) {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 156px;
      padding: unset;
      box-shadow: unset;
    }

    &__icon {
      @media (max-width: 560px) {
        height: 48px;
      }
    }

    &__subtitle {
      max-width: 308px;
      margin-top: 32px;
      margin-bottom: 48px;
      color: var(--dark-grey);
      text-align: center;

      @media (max-width: 760px) {
        max-width: 327px;
        margin-top: 16px;
        margin-bottom: 16px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  &-recommendations {
    &__list {
      position: relative;
      width: 100%;
    }

    &__card {
      &:not(:last-of-type) {
        margin-right: 16px;
      }
    }

    &__waiting {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 197px;
    }

    &__card-single-item {
      max-width: 359px;

      @media (max-width: 768px) {
        min-width: 297px;
      }
    }
  }

  @media (max-width: 760px) {
    padding: 20px 24px 16px;
    box-shadow: unset;
  }
}

.indicators {
  padding: 24px 32px;
  background: var(--white);
  border-radius: 12px;
  box-shadow: 0 8px 52px #f3f3f3;

  &__selectors {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: 24px;
    line-height: 32px;

    @media (max-width: 768px) {
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__date-selectors {
    display: flex;
    align-items: center;
  }

  &__date-range {
    display: flex;
    align-items: center;
  }

  &__range {
    &-divider {
      margin-right: 16px;
      margin-left: 16px;
    }
  }

  &-chart {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 32px;

      &__norm {
        color: var(--dark-grey);

        &-square {
          width: 24px;
          min-width: 24px;
          height: 24px;
          margin-right: 8px;
          margin-left: 10px;
          background: var(--background-secondary-lighter);
          border-radius: 8px;
        }

        &-zone {
          display: flex;
          align-items: center;
        }

        &-value {
          width: 100%;
        }
      }
    }
  }

  @media (min-width: 768px) {
    margin-bottom: 24px;
  }

  @media (max-width: 1000px) {
    &__selectors {
      flex-wrap: wrap;
    }

    &__date-selectors {
      flex-basis: 100%;
      justify-content: space-between;
      margin-top: 17px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__dropdown {
    margin-right: 32px;

    @media (max-width: 768px) {
      margin-right: 0;
    }
  }

  @media (max-width: 760px) {
    padding: 0;
    box-shadow: none;

    &__dropdown {
      width: 100% !important;
    }

    &__range {
      &-title {
        display: none;
      }

      &-divider {
        margin-right: 12px;
        margin-left: 12px;
      }
    }

    &__date-range {
      justify-content: space-between;
      width: 100%;
    }
  }
}

.slider-navigation {
  &__item {
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 32px;
    height: 32px;
    margin: 0;
    padding: 0;
    background: var(--white);
    border: 1px solid #ededed;
    border-radius: 50%;

    &_next {
      top: calc(50% - 16px);
      right: -16px;
      cursor: pointer;
    }

    &_prev {
      top: calc(50% - 16px);
      left: -16px;
      cursor: pointer;
    }

    &_disabled {
      display: none;
    }
  }

  &__icon {
    path {
      fill: #676767;
    }

    &_prev {
      transform: rotate(90deg);
    }

    &_next {
      transform: rotate(-90deg);
    }
  }
}

.tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 288px;
  padding: 8px 24px !important;
  color: var(--white);
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 18px !important;
  letter-spacing: -0.01em !important;
  background: var(--background-black) !important;
  border-radius: 8px !important;
  opacity: 1 !important;

  @media (max-width: 560px) {
    position: fixed !important;
    top: unset !important;
    right: 24px !important;
    bottom: 76px !important;
    left: 24px !important;
    max-width: unset !important;
    margin-left: unset !important;
  }
}

.my-expert {
  &__container {
    display: flex;
    width: 100%;
    margin-top: 24px;
    background: var(--white);
    border: 2px solid var(--white);
    border-radius: 24px;
    box-shadow: 0 8px 52px #f3f3f3;

    &:hover {
      border: 2px solid var(--background-primary-lighter);
      cursor: pointer;
    }
  }

  &__container_info-modal-opened {
    background: var(--background-primary-lighter);
    border: 2px solid var(--background-primary-lighter);
  }

  &__inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__header {
    display: flex;

    @media (max-width: 768px) {
      margin-bottom: 20px;
    }
  }

  &__avatar {
    display: flex;
    width: 120px;
    height: 120px;
    margin-right: 18px;
    object-fit: cover;

    @media (max-width: 768px) {
      width: 48px;
      height: 48px;
      margin-right: 12px;
    }

    img {
      width: 120px;
      height: 120px;
      object-fit: cover;
      border-radius: 50%;

      @media (max-width: 768px) {
        width: 48px;
        height: 48px;
      }
    }
  }

  &__expert-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 230px;
  }

  &__username {
    &_container {
      display: flex;
      flex-direction: row;
      max-width: 230px;
      margin-bottom: 8px;
    }

    &_name {
      display: flex;
      width: 100%;
      color: var(--black);
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;

      @media (max-width: 768px) {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }

  &__age {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__expert-about {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-right: 49px;
    column-gap: 18px;

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 24px;
    }
  }

  &__about-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 170px;
    padding: 24px;
    background-color: var(--light-grey);
    border-radius: 24px;

    @media (max-width: 768px) {
      padding: 0;
      background-color: unset;
    }
  }

  &__card-header {
    margin-bottom: 12px;
    color: #8e8e8e;
    font-size: 16px;
    line-height: 20px;
  }

  &__card-content {
    overflow: hidden;
    color: var(--black);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__chat-icon {
    width: 100%;
    height: 100%;
    margin-right: 12px;

    @media (max-width: 768px) {
      display: flex;
      align-items: center;
      width: max-content;
    }
  }

  &__chat-anchor {
    color: var(--gark-grey);
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    white-space: nowrap;
  }
}

.experts {
  &__container {
    display: flex;
    width: 100%;
    margin-top: 24px;
  }

  &__inner {
    display: grid;
    grid-template-columns: 1fr 1fr 50px;
    justify-content: space-between;
    width: 100%;
    column-gap: 16px;
    filter: drop-shadow(0 8px 52px #f3f3f3);

    @media (max-width: 768px) {
      overflow-y: scroll;
    }
  }

  &__show-all {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    background-color: var(--white);
    border-radius: 24px;
    cursor: pointer;
  }
}
