.manually-import-container {
  padding: 32px;
  background: var(--white);
  border-radius: 16px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (min-width: 768px) {
    max-width: unset;
  }

  @media (max-width: 576px) {
    padding: 0 0 16px;
    background: none;
    box-shadow: none;
  }
}

.manually-import-header {
  margin-bottom: 12px;

  @media (max-width: 576px) {
    flex-direction: column;
    margin-bottom: 4px;
  }

  &__input {
    width: 100%;
    max-width: 418px;
    margin-right: 32px;
    margin-bottom: 20px;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: 576px) {
      margin-right: 0;
    }
  }
}

.manually-import-biomarkers {
  width: 100%;
  max-width: 100%;

  .biomarkers__header {
    margin-bottom: 24px;
    color: var(--dark-grey);
  }
}

.add-button {
  margin-bottom: 25px;
  padding: 0;
  color: var(--background-secondary-dark);
  background: none;
  border: none;
  cursor: pointer;
  appearance: none;

  &__icon {
    padding: 0 4px;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    font-style: normal;
    line-height: 17px;
  }
}
