.card {
  position: relative;
  display: flex;
  flex: 1 1;
  flex-direction: column;
  justify-content: space-between;
  min-width: 270px;
  min-height: 350px;
  padding: 24px;
  background-color: white;
  border: none;
  border-radius: 24px;
  box-shadow: 0 8px 52px #f3f3f3;

  @media (max-width: 760px) {
    flex-basis: 100%;
    margin-bottom: 16px;
  }

  &__button {
    min-width: 161px;
  }

  &__send_button {
    margin-top: 10px;
  }

  &__title {
    margin: 10px 0;
    overflow-wrap: break-word;

    @media (max-width: 576px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  &__image {
    height: 100%;
    max-height: 220px;
    object-fit: cover;
    border-radius: 14px;
  }
}
