.avatar {
  min-width: 42px;
  height: 42px;
  background-color: var(--lighter-grey);
  border: none;
  border-radius: 16px;
  outline: unset;
  cursor: pointer;

  &__wrapper {
    display: flex;
    align-items: center;

    & > :first-of-type {
      margin-right: 20px;
    }
  }

  @media (max-width: 560px) {
    margin-right: unset;
  }
}

.profile-name {
  display: inline-block;
  display: -webkit-box;
  max-width: 30vw;
  overflow: hidden;
  color: var(--black);
  font-weight: 500;
  font-size: 16px;
  font-style: normal;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: start;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: 1100px) {
    max-width: 200px;
  }

  @media (min-width: 760px) and (max-width: 960px) {
    display: none;
  }

  @media (max-width: 760px) {
    max-width: 30vw;
  }

  @media (max-width: 560px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}

.main-menu-button {
  position: relative;
  cursor: pointer;

  &__dots {
    @media (max-width: 560px) {
      display: none;
    }
  }
}
