.form {
  &__fields {
    display: grid;
    grid-gap: 32px;
    grid-template-columns: 1fr 1fr;
    margin-top: 48px;

    @media (max-width: 1000px) {
      margin-top: 28px;
    }

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }

  // @media (max-width: 540px) {
  //   margin-top: 17px;
  // }

  &__field {
    width: 100%;
  }

  &__button {
    width: 200px;

    @media (max-width: 640px) {
      width: 100%;
    }

    margin-top: 40px;
    margin-left: auto;
  }
}
