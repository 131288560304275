.header {
  //z-index: 2;
  height: 100px;
  min-height: 100px;
  padding: 24px 60px;
  background-color: var(--white);

  &__link {
    position: relative;
    margin-right: 28px;
  }

  h1 {
    margin: 0;
    font-size: 32px;
    line-height: 39px;
    letter-spacing: -0.01em;
  }

  .logo {
    margin-right: 20px;

    svg {
      display: flex;
    }
  }

  @media (max-width: 768px) {
    padding: 16px 24px;
  }

  &-content {
    &_hide-mobile {
      display: flex;
    }

    &__message-icon {
      path {
        fill: var(--dark-grey);
      }

      &_blue {
        path {
          fill: var(--blue);
        }
      }
    }

    @media (max-width: 930px) {
      button {
        margin-right: 15px !important;
      }
    }

    @media (max-width: 760px) {
      &_hide-mobile {
        display: none;
      }
    }
  }

  @media (max-width: 640px) {
    height: 64px;
    min-height: 64px;

    h1 {
      font-size: 20px;
      line-height: 24px;
    }

    .logo {
      svg {
        width: auto;
        height: 25px;
      }
    }
  }
}

.marker {
  position: absolute;
  top: 13px;
  left: 31px;
  z-index: 99;
  display: block;
  width: 12px;
  height: 12px;
  background-color: var(--red);
  border-radius: 50%;
}
