.diary {
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (min-width: 760px) and (max-width: 1230px) {
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
    }

    @media (max-width: 760px) {
      align-items: flex-start;
    }

    @media (max-width: 760px) {
      flex-direction: column;
      gap: 24px;
      align-items: flex-start;
    }
  }

  &__button-container {
    gap: 15px;

    @media (min-width: 760px) and (max-width: 1230px) {
      flex-direction: column;
      width: 100%;
    }

    @media (max-width: 760px) {
      flex-direction: column;
      width: 100%;
    }
  }

  &__tabs {
    display: flex;
    margin-top: 48px;
    overflow-x: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    @media (min-width: 760px) and (max-width: 1230px) {
      margin-top: 24px;
    }

    @media (max-width: 640px) {
      margin-top: 24px;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__tab {
    padding: 10px 32px;
    color: var(--secondary-black);
    font-weight: 500;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: -0.01em;
    white-space: nowrap;
    background: var(--light-grey);
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background 0.2s;

    &:hover {
      background: var(--grey);
    }

    &:not(:last-child) {
      margin-right: 21px;
    }

    @media (max-width: 540px) {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }

    &_selected {
      color: var(--white);
      background: var(--background-primary);

      &:hover {
        background: var(--background-primary);
      }
    }
  }
}

.table {
  &__header {
    margin-top: 32px;
    margin-right: -48px;
    margin-left: -48px;

    @media (min-width: 760px) and (max-width: 1230px) {
      display: none;
    }

    @media (max-width: 640px) {
      display: none;
    }
  }

  &__body {
    margin-top: 28px;
  }

  &__row {
    display: flex;
    align-items: center;

    @media (min-width: 760px) and (max-width: 1230px) {
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
    }

    @media (max-width: 640px) {
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;
    }

    &:not(:last-child) {
      margin-bottom: 24px;
      padding-bottom: 24px;
      border-bottom: 1px solid var(--light-grey);
    }

    &_header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      > p {
        font-weight: 700;
      }
    }
  }

  &__cell {
    min-width: 0;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &_subhead {
      margin-bottom: 12px;

      @media (min-width: 640px) {
        display: none;
      }
    }
  }

  &__close-icon {
    display: flex;
    margin-left: 10px;
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
      svg path {
        transition: 0.3s;
        fill: var(--red);
      }
    }
  }

  &__button {
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid var(--light-grey);
  }
}

.text-areas {
  margin-top: 40px;
  padding-top: 40px;
  border-top: 1px solid var(--light-grey);
}

.text-area {
  &:not(:last-child) {
    margin-bottom: 32px;
  }

  &__title {
    margin-bottom: 10px;

    &_grey {
      color: var(--dark-grey);
    }
  }
}

.section {
  margin-top: 40px;
}

.second-section {
  margin-top: 32px;
}

.simple-section {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 40px;
}
