html,
body,
#root {
  height: 100%;
  scroll-behavior: smooth;
}

#root {
  max-width: 1660px;
  margin: auto;
}

html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: border-box;
  font-family: Inter, sans-serif;
}

body {
  margin: 0;
  color: var(--black);
  font-weight: 400;
  font-size: 1rem;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    Roboto,
    'Helvetica Neue',
    Arial,
    'Noto Sans',
    sans-serif,
    'Apple Color Emoji',
    'Segoe UI Emoji',
    'Segoe UI Symbol',
    'Noto Color Emoji';
  line-height: 1.5;
  text-align: left;
  background-color: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.noscroll {
  overflow: hidden;
}

input {
  filter: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--light-grey) inset !important;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: middle;
  border-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

button {
  margin: 0;
  padding: 0;
}

.visually-hidden {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  margin: 0 !important;
  padding: 0 !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  clip: rect(0 0 0 0) !important;
  clip-path: inset(100%) !important;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}

@media (min-width: 1440px) {
  .container {
    max-width: 1430px;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.flexbox {
  display: flex;
}

.flexbox-inline {
  display: inline-flex;
}

.direction-column {
  flex-direction: column;
}

.direction-row {
  flex-direction: row;
}

.flexwrap-wrap {
  flex-wrap: wrap;
}

.align-start {
  align-items: flex-start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.align-baseline {
  align-items: baseline;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-end {
  justify-content: flex-end;
}

.nopadding {
  padding: 0;
}

.nomargin {
  margin: 0;
}

.nodecoration {
  text-decoration: none;
}

.capitalize {
  text-transform: capitalize;
}

.no-scroll {
  overflow: hidden;
}
