.notification {
  font-family: 'Inter', sans-serif;
  letter-spacing: -0.01em;

  &__container {
    background: var(--white);
    box-shadow: 0 0 10px #f1f1f1;
  }

  &__icon {
    margin-right: 12px;
  }

  &__content {
    &--error {
      padding: 0 0 8px;
      color: var(--red);
    }

    &--success {
      padding: 0 0 8px;
      color: var(--background-secondary-dark);
    }

    &--text {
      color: var(--black);
      font-weight: normal;
      font-size: 18px;
      font-style: normal;
      line-height: 22px;
    }

    @media (max-width: 580px) {
      &--error {
        font-size: 18px;
        line-height: 22px;
      }

      &--success {
        font-size: 18px;
        line-height: 22px;
      }

      &--text {
        font-size: 14px;
        line-height: 18px;
      }
    }
  }
}
