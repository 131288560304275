.empty_tab {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.empty_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 457px;
  margin-top: 220px;
  margin-bottom: 220px;

  @media (max-width: 768px) {
    margin-top: 140px;
    margin-bottom: 140px;
  }
}

.empty_header {
  margin-top: 10px;
  margin-bottom: 24px;
  color: var(--black);
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-align: center;

  @media (max-width: 768px) {
    margin-bottom: 16px;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }
}

.empty_content {
  margin-bottom: 40px;
  color: var(--dark-grey);
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
}
