.avatar {
  min-width: 42px;
  height: 42px;
  margin-right: 14px;
  background-color: var(--lighter-grey);
  border-radius: 16px;
  cursor: pointer;

  @media (max-width: 560px) {
    margin-right: unset;
  }
}

.profile-name {
  display: inline-block;
  display: -webkit-box;
  max-width: 30vw;
  margin-right: 16px;
  overflow: hidden;
  color: var(--black);
  font-weight: bold;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  line-height: 18px;
  letter-spacing: -0.01em;
  text-align: end;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  @media (max-width: 1100px) {
    max-width: 250px;
  }

  @media (max-width: 760px) and (min-width: 960px) {
    display: none;
  }

  @media (max-width: 760px) {
    max-width: 30vw;
  }

  @media (max-width: 560px) {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
  }
}

.main-menu-button {
  position: relative;
  cursor: pointer;

  &__dots {
    @media (max-width: 560px) {
      display: none;
    }
  }
}
