.content {
  padding: 16px 0 40px;
  color: var(--dark-grey);
}

.buttons {
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 100%;
  column-gap: 16px;

  button {
    @media (max-width: 768px) {
      min-width: unset !important;
    }
  }
}
